import React, { Component } from "react";

export default class VisionMissionArea extends Component {
    render() {
        return (
            <div className="mission__area section-padding">
                <div className="container">
                    <div className="row mb-60">
                        <div className="col-xl-12 col-lg-12">
                            <div className="mission__area-title">
                                <span className="subtitle-one" style={{ fontFamily: "Source Sans Pro" }}>Our Mission & Vision</span>
                                {/* <span className="subtitle-two">03</span> */}
                                <h2 style={{ fontFamily: "Play Fair" }}>Our Organization</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 lg-mb-30">
                            <div className="mission__area-left wow fadeInUp" data-wow-delay="0.4s">
                                <div className="mission__area-left-item mb-30">
                                    <div className="mission__area-left-item-icon">
                                        <i className="flaticon-accounting"></i>
                                    </div>
                                    <div className="mission__area-left-item-content">
                                        <h3 style={{ fontFamily: "Source Sans Pro" }}>Our Mission</h3>
                                        {/* <p> */}
                                        {this.props.Mission ? <div dangerouslySetInnerHTML={{ __html: this.props.Mission }}></div> : "NA"}

                                        {/* </p> */}
                                    </div>
                                </div>
                                <div className="mission__area-left-item">
                                    <div className="mission__area-left-item-icon">
                                        <i className="flaticon-project"></i>
                                    </div>
                                    <div className="mission__area-left-item-content">
                                        <h3 style={{ fontFamily: "Source Sans Pro" }}>Our Vision</h3>
                                        {/* <p> */}
                                        {this.props.Vision ? <div dangerouslySetInnerHTML={{ __html: this.props.Vision }}></div> : "NA"}
                                        {/* </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="mission__area-right wow fadeInUp" data-wow-delay="0.8s">
                                <div className="mission__area-right-image mb-30">
                                    <h3 style={{ fontFamily: "Source Sans Pro" }}>Our Creed</h3>
                                    <h4 className="mt-10 mb-30">
                                        {this.props.Creed ? <div dangerouslySetInnerHTML={{ __html: this.props.Creed }}></div> : "NA"}
                                    </h4>
                                    {/* <h4 className="mt-10 mb-30">
                                        <img src={blockTitleHeart} style={{ width: 15 }} alt="" />{this.state.RecordsFlag3 ? <div dangerouslySetInnerHTML={{ __html: this.state.Records3.SettingsValuesContent }}></div> : "NA"}
                                    </h4> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}