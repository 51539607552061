import React, { Component } from "react"
import { Link } from "react-router-dom";

const date = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();


let currentDate = `${year}-${month}-${day}`;
console.log("currentDate = ", currentDate);

export default class EventContent extends Component {
    render() {
        return (
            <>
                {/* <!-- Blog Area Start --> */}
                <div className="blog__area section-padding">
                    <div className="container">
                        <div className="row">
                            {this.props.data.map((event, index, key = { index }) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 mb-30" key={index}>
                                    <div className="blog__area-item wow fadeInUp" data-wow-delay="0.4s">
                                        <div className="blog__area-item-image">
                                            <img src={event.EventImagePath} alt="Event" style={{ height: 200, width: 400 }} />
                                        </div>
                                        <div className="blog__area-item-content">
                                            <div className="blog__area-item-content-date">
                                                {/* <span>{event.StartDate}</span> */}
                                                <p>{event.DateFromTxt}</p>
                                            </div>
                                            <h3>
                                                <Link to={"/event/detail?id=" + event.EventId}>{event.EventName}</Link>
                                                {/* <a href="blog-details.html">{event.EventTitle}</a> */}
                                            </h3>
                                            <Link to={"/event/detail?id=" + event.EventId}>Details Click here <i className="flaticon-right-arrow"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <div className="row">
                            <div className="col-xl-12">
                                <div className="pagination__area t-center mt-50  wow fadeInUp" data-wow-delay="0.4s">
                                    <ul>
                                        <li><a href={onclick = "return: false"}><i className="fas fa-chevron-left"></i></a>
                                        </li>
                                        <li><a className="active" href={onclick = "return: false"}>01</a>
                                        </li>
                                        <li><a href={onclick = "return: false"}>02</a>
                                        </li>
                                        <li><a href={onclick = "return: false"}>03</a>
                                        </li>
                                        <li><a href={onclick = "return: false"}><i className="fas fa-chevron-right"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <!-- Blog Area End --> */}
            </>
        );
    }
}

