import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ErrorContent from "../components/error/ErrorContent";
import Layout from "../components/Layout";

export default class Error extends Component {
    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>Error | Madurai Pandian</title>
                    {/* <meta name='description' content='Error - Madurai Pandian' /> */}
                </Helmet>
                <Layout>
                    <ErrorContent />
                </Layout>
            </>
        );
    }
}