import React, { Component } from "react";
import { Helmet } from "react-helmet";
import GallerySection from "../components/gallery/GallerySection";
// import SubscribeArea from "../components/home/SubscribeArea";
import Layout from "../components/Layout";
import PageHeader from "../components/service/PageHeader";

export default class Gallery extends Component {
    componentDidMount() {
        console.log("componentDidMount called");
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>Gallery | Madurai Pandian</title>
                    {/* <meta name='description' content='Gallery - Madurai Pandian' /> */}
                </Helmet>
                <Layout>
                    <PageHeader bannerTitle="Gallery" />
                    <GallerySection />
                    {/* <SubscribeArea/> */}
                </Layout>
            </>
        );
    }
}