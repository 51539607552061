import React, { Component } from "react";

export default class MaduraiPandianContent extends Component {
    render() {
        return (
            <>
                <div className="about__area-two section-padding">
                    <div className="container pb-100">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 mb-10">
                                <div className="about__area-two-left mr-70 wow fadeInUp">
                                    <div className="about__area-two-left-image">
                                        <img src="/assets/images/about/about-1.jpg" alt="" />
                                    </div>
                                    <p></p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 mb-20">
                                <div className="about__area-title-right wow fadeInUp">
                                    <span className="subtitle-one" style={{fontFamily:"Source Sans Pro"}}>About JCI Madurai Pandian</span>
                                    <p className="mb-20"  style={{fontFamily:"Play Fair"}}>  <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> Chapter established on August 9th, 1973 and extended from “JCI Madurai” and named as “JCI Madurai Pandian”. Our Charter President is Jc S. Rajagopal.
                                    </p>
                                    <p className="mb-20"  style={{fontFamily:"Play Fair"}}>  <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> Our Bulletin in the name “Jasmin” is been publishing from 1973. Past 50 years bulletin is been published. </p>
                                    <p className="mb-20" style={{fontFamily:"Play Fair"}}>  <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> Chapter is been performing for the last 49years actively. </p>
                                    <p className="mb-20" style={{fontFamily:"Play Fair"}}>  <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> We have <br/>
                                        1 JCI Vice President <br/>
                                        1 Executive Vice President <br/>
                                        2 State Vice President <br/>
                                        1 State Parliamentarian <br/>
                                        1 Zone President <br/>
                                        1 National President
                                    </p>
                                    <p className="mb-20" style={{fontFamily:"Play Fair"}}>  <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> We have hosted a State Conference in 1991 </p>
                                    <p className="mb-20" style={{fontFamily:"Play Fair"}}>  <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> In 1993, We have hosted AOS </p>
                                    <p className="mb-20" style={{fontFamily:"Play Fair"}}>  <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> We have hosted zone conference trice. </p>
                                    <p className="mb-20" style={{fontFamily:"Play Fair"}}>  <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> Hosted CAPP to Madurai Corporation Mayor and Councilors in 1996. </p>
                                    <p className="mb-20" style={{fontFamily:"Play Fair"}}>  <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> We have hosted LOTS for more 5 then years. </p>
                                    <p className="mb-20" style={{fontFamily:"Play Fair"}}>  <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> We have hosted Trainers update, Zone and Pre Zone Workshop once each. </p>
                                    <p className="mb-20" style={{fontFamily:"Play Fair"}}>  <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> We have 5 National Trainers and 13 Zone Trainers. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
