import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}

export default class Header extends Component {
    constructor() {
        super();

        this.state = {
            LoadingFlag: false,
            Blocks: "",
            Logo: "",

            MobileNo: "",
            MailId: "",
            Address: "",
            Mission: "",
            Vision: "",
            Creed: "",
            Facebook: "",
            Twitter: "",
            Instagram: "",
            LinkedIn: "",
            MembersOath: "",
            OfficersOath: "",
            FoundersPerspective: "",
            CompanyName: "",
            PresidentLogo: "",
        };
    }

    async pageDetails() {
        console.log("pageDetails called ");
        var url = Api_url + `/api/app/app-page/show`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            PageName: 'header',
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var flag = res.data.flag;
                var blocks = res.data.records['blocks'];
                var president_logo = res.data.records['president_logo'];

                console.log({ flag, blocks });
                console.log({ president_logo });

                this.setState({ LoadingFlag: false, Blocks: blocks, PresidentLogo: president_logo });

                var mobile_no = blocks.SettingsValues['mobile_no'].SettingsValuesContent;
                var mail_id = blocks.SettingsValues['mail_id'].SettingsValuesContent;
                var address = blocks.SettingsValues['address'].SettingsValuesContent;
                var mission = blocks.SettingsValues['mission'].SettingsValuesContent;
                var vision = blocks.SettingsValues['vision'].SettingsValuesContent;
                var creed = blocks.SettingsValues['creed'].SettingsValuesContent;
                var facebook = blocks.SettingsValues['facebook'].SettingsValuesContent;
                var twitter = blocks.SettingsValues['twitter'].SettingsValuesContent;
                var instagram = blocks.SettingsValues['instagram'].SettingsValuesContent;
                var linkedin = blocks.SettingsValues['linkedin'].SettingsValuesContent;
                var members_oath = blocks.SettingsValues['members_oath'].SettingsValuesContent;
                var officers_oath = blocks.SettingsValues['officers_oath'].SettingsValuesContent;
                var founders_perspective = blocks.SettingsValues['founders_perspective'].SettingsValuesContent;
                var company_name = blocks.SettingsValues['company_name'].SettingsValuesContent;

                var logo = president_logo.SettingsValues['president_logo'].FilePath;

                this.setState({
                    MobileNo: mobile_no,
                    MailId: mail_id,
                    Address: address,
                    Mission: mission,
                    Vision: vision,
                    Creed: creed,
                    Facebook: facebook,
                    Twitter: twitter,
                    Instagram: instagram,
                    LinkedIn: linkedin,
                    MembersOath: members_oath,
                    OfficersOath: officers_oath,
                    FoundersPerspective: founders_perspective,
                    CompanyName: company_name,
                    Logo: logo,
                });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    componentDidMount() {
        console.log("componentDidMount called ");
        this.pageDetails();
    }

    render() {
        return (
            <>
                {/* <!-- Header Area Start --> */}
                < div className="header__area" >
                    {/* <!-- Logo Area Start --> */}
                    < div className="custom-container" >
                        <div className="header__area-logo">
                            <div className="header__area-logo-area">
                                <a href={onclick = "return: false"}>
                                    {this.state.Logo !== "" ? <img src={this.state.Logo} style={{ width: "80px", height: "80px", borderRadius: "100px" }} alt="" /> : ""}
                                    {/* <img src="/assets/img/logo.png" alt="" /> */}
                                </a>
                            </div>
                        </div>
                    </div >
                    {/* <!-- Logo Area Start --> */}
                    {/* <!-- TopBar Area Start --> */}
                    <div className="header__area-topbar-area">
                        <div className="header__area-topbar custom-container">
                            <div className="header__area-topbar-left">
                                <div className="header__area-topbar-left-info md-mb-10">
                                    <ul>
                                        <li>
                                            <div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: this.state.MobileNo }}></div>
                                        </li>
                                        <li>
                                            <div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: this.state.MailId }}></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="header__area-topbar-right">
                                <div className="header__area-topbar-right-social">
                                    <ul>
                                        <li>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.Facebook }} />
                                        </li>
                                        <li>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.Twitter }} />
                                        </li>
                                        <li>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.Instagram }} />
                                        </li>
                                        <li>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.LinkedIn }} />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- TopBar Area Start --> */}
                    {/* <!-- Menu Bar Area Start --> */}
                    <div className="header__area-menubar-area">
                        <div className="header__area-menubar d-flex align-items-center custom-container">
                            <div className="header__area-menubar-logo custom-container">
                                {/* <a href={onclick = "return: false"}> </a>
                                <div className="responsive-menu"></div> */}
                                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                                    <div className="container-fluid">
                                        <a className="navbar-brand" href={onclick = "return: false"}>
                                            {this.state.Logo !== 0 ? <img src={this.state.Logo} style={{ width: "70px", height: "70px", borderRadius: "100px" }} alt="" /> : ""}
                                        </a>
                                        <button className="navbar-toggler" data-mdb-right="true" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <hr />
                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                                <li className="nav-item">
                                                    <Link className="nav-link text-dark" to="/">Home</Link>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle text-dark" href={onclick = "return: false"} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">About Us</a>
                                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <li><Link to="/about-jci" className="dropdown-item text-dark">About JCI</Link></li>
                                                        <li><Link to="/about-jci-madurai-pandian" className="dropdown-item text-dark">About JCI Madurai Pandian</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle text-dark" href={onclick = "return: false"} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Event</a>
                                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <li><Link to="/event/list" className="dropdown-item text-dark">Event</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle text-dark" href={onclick = "return: false"} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Team</a>
                                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <li><Link to="/team/governing-board-members" className="dropdown-item text-dark">Governing Board Members</Link></li>
                                                        <li><Link to="/team/past-presidents" className="dropdown-item text-dark">Past Presidents</Link></li>
                                                        <li><Link to="/team/members" className="dropdown-item text-dark">Members</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link text-dark" to="/gallery">Gallery</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link text-dark" to="/contact">Contact</Link>
                                                </li>
                                            </ul>
                                            <hr />
                                            <div className="header-login">
                                                <a className="theme-btn" target="_blank" rel="noopener noreferrer" href="https://admin.jcimaduraipandian.org/">Login</a>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                                {/* <p>hello</p> */}
                            </div>
                            <div className="header__area-menubar-main-menu">
                                <ul id="mobilemenu">
                                    <li><Link to="/">Home</Link></li>
                                    <li className="header__area-menubar-main-menu-icon"><a href={onclick = "return: false"}>About Us</a>
                                        <ul className="sub-menu">
                                            <li><Link to="/about-jci">About JCI</Link></li>
                                            <li><Link to="/about-jci-madurai-pandian">About JCI Madurai Pandian</Link></li>
                                        </ul>
                                    </li>

                                    <li className="header__area-menubar-main-menu-icon"><a href={onclick = "return: false"}>Event</a>
                                        <ul className="sub-menu">
                                            <li><Link to="/event/list">Event</Link></li>
                                        </ul>
                                    </li>
                                    <li className="header__area-menubar-main-menu-icon"><a href={onclick = "return: false"}>Team</a>
                                        <ul className="sub-menu">
                                            <li><Link to="/team/governing-board-members">Governing Board Members</Link></li>
                                            <li><Link to="/team/past-presidents">Past Presidents</Link></li>
                                            <li><Link to="/team/members">Members</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/gallery">Gallery</Link></li>
                                    <li><Link to="/bulletin">Bulletin</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                </ul>
                            </div>
                            <div className="header__area-menubar-btn">
                                <a className="theme-btn" target="_blank" rel="noopener noreferrer" href="https://admin.jcimaduraipandian.org/">Login</a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Menu Bar Area Start --> */}
                </div >
                {/* <!-- Header Area End --> */}
            </>
        );
    }
}
