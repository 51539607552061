import React, { Component } from "react";
import { Helmet } from "react-helmet";
import EventDetailContent from "../../components/event/EventDetailContent";
import Layout from "../../components/Layout";
import PageHeader from "../../components/service/PageHeader";

export default class EventDetail extends Component {
    componentDidMount() {
        console.log("EventDetail componentDidMount called ");
        let currentLocation = window.location.toString();
        console.log({ currentLocation });
    }
    render() {
        return (
            <>
            <Helmet>
                <title>EventDetail | Madurai Pandian</title>
                {/* <meta name='description' content='EventDetail - Madurai Pandian' /> */}
            </Helmet>
                <Layout>
                    <PageHeader bannerTitle="Event Detail" />
                    <EventDetailContent data={window.location}/>
                </Layout>
            </>
        );
    }
}