import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class PastPresidentMemberContent extends Component {
    render() {
        return (
            <>
                {/* <!-- Team Area Start --> */}
                <div className="team__area section-padding mt=0">
                    <div className="container">
                        <div className="row">
                            {this.props.members.map((member, index) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 mb-30" key={index}>
                                    <div className="team__area-item">
                                        <div className="team__area-item-image">
                                            <img src={member.ProfilePic} alt={member.MemberName} style={{ height: 400, width: 500 }} />
                                            <div className="team__area-item-image-content">
                                                <p>{member.LomName}</p>
                                                <h3>
                                                    <Link to={"/team/profile?id=" + member.MemberId}>{member.MemberName}</Link>
                                                </h3>
                                                <div className="team__area-item-image-content-social">
                                                    <div className="team__area-item-image-content-social-icon">
                                                        <ul>
                                                            <li><a href={onclick = "return: false"}><i className="fab fa-facebook-f"></i></a>
                                                            </li>
                                                            <li><a href={onclick = "return: false"}><i className="fab fa-twitter"></i></a>
                                                            </li>
                                                            <li><a href={onclick = "return: false"}><i className="fab fa-linkedin-in"></i></a>
                                                            </li>
                                                        </ul>
                                                    </div>	<a href={onclick = "return: false"}><i className="flaticon-share-1"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* <!-- Team Area End --> */}
            </>
        );
    }
}