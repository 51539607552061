// import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Component } from "react";

export default class Layout extends Component {
    render() {
        return (
            <>
                <Header />
                {/* <Outlet /> */}
                <main>{this.props.children}</main>
                <Footer />
            </>
        );
    }
}