import React, { Component } from "react";
import axios from "axios";

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class TeamProfileContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            Records: {},
            LoadingFlag: false,
            RecordsFlag: false
        };
    }
    async getDetails(id) {
        console.log("getDetails called ");

        // var id = this.state.id;
        console.log("id=" + id);

        if (id > 0) {
            var url = Api_url + `/api/app/members/search`;
            console.log("url=" + url);
            var postData = {
                "UserInterface": 3,
                "MemberDetailsId" : id,
            };
            this.setState({ LoadingFlag: true });
            console.log({ postData });
            await axios
                .post(url, postData, axiosConfig)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    console.log("res=" + JSON.stringify(res));

                    this.setState({ LoadingFlag: false });

                    var flag = res.data.flag;
                    var records = res.data.records;
                    console.log({ flag, records });

                    this.setState({ RecordsFlag: flag, Records: records });
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
    };

    componentDidMount() {
        console.log("MemberDetailContent componentDidMount called ");
        window.scrollTo(0, 0);
        let currentLocation = this.props.data;
        currentLocation = currentLocation.toString();
        console.log({ currentLocation });
        let searchParams = currentLocation.split('id=');
        var id = searchParams[1] ? parseInt(searchParams[1]) : '';
        console.log({ searchParams });
        console.log({ id });
        this.getDetails(id);
    }

    render() {
        return (
            <>
                {/* <!-- Team Details Area Start --> */}
                <div className="team__details section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 lg-mb-30">
                                <div className="team__details-left">
                                    <div className="team__details-left-item mb-30 wow fadeInUp" data-wow-delay="0.4s">
                                        <div className="team__details-left-item-image">
                                            <img src={this.state.Records.MemberImage} alt="" />
                                        </div>
                                        <div className="team__details-left-item-content">
                                            <ul>
                                                <li><a href={onclick = "return: false"}><i className="fab fa-facebook-f"></i></a>
                                                </li>
                                                <li><a href={onclick = "return: false"}><i className="fab fa-twitter"></i></a>
                                                </li>
                                                <li><a href={onclick = "return: false"}><i className="fab fa-linkedin-in"></i></a>
                                                </li>
                                                <li><a href={onclick = "return: false"}><i className="fab fa-pinterest-p"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/*
                                    <div className="all__sidebar-item wow fadeInUp" data-wow-delay="0.4s">
                                        <h3 className="mb-20">Contact Me</h3>
                                        <div className="all__sidebar-item-quote">
                                            <form action={onclick = "return: false"}>
                                                <div className="row">
                                                    <div className="col-sm-12 mb-30">
                                                        <div className="contact__form-right-form-item">
                                                            <i className="far fa-user"></i>
                                                            <input type="text" name="name" placeholder="Your Name" required="required" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 mb-30">
                                                        <div className="contact__form-right-form-item">
                                                            <i className="far fa-envelope-open"></i>
                                                            <input type="text" name="email" placeholder="Email Address" required="required" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 mb-30">
                                                        <div className="contact__form-right-form-item">
                                                            <textarea name="message" rows="5" placeholder="Message"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="contact__form-right-form-item">
                                                            <button className="theme-btn" type="submit">Submit Now<i className="flaticon-right-arrow"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    */}
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8">
                                <div className="team__details-right">
                                    <div className="team__details-right-item">
                                        <div className="team__details-right-item-content mb-30">
                                            <h3>{this.state.Records.Fullname}</h3>
                                            <span>{this.state.Records.MemberDesignation}</span>
                                            {/* <p className="mb-20">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit</p>
                                            <p>aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam,</p> */}
                                        </div>

                                        <div className="team__details-right-item-skill mb-40">
                                            <div className="team__details-left-item-content">
                                                <ul>
                                                    <li><a href={onclick = "return: false"}><i className="fa fa-phone"></i> {this.state.Records.MobileNo}</a>
                                                    </li> &nbsp; &nbsp;
                                                    {/* <li><a href={onclick = "return: false"}><i className="fab fa-whatsapp"></i> {this.state.Records.MobileNo}</a>
                                                    </li> &nbsp; &nbsp; */}
                                                    <li><a href={onclick = "return: false"}><i className="fa fa-envelope"></i> {this.state.Records.EmailId}</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            {/* <div className="team__details-right-item-skill-item mb-30">
                                                <div className="team__details-right-item-skill-content">
                                                    <h5>Repairs</h5>
                                                    <span className="team__details-right-item-skill-count">
                                                        <span className="counter">80</span>%</span>
                                                </div>
                                                <div className="team__details-right-item-skill-inner">
                                                    <div className="team__details-right-item-skill-bar" data-width="80"></div>
                                                </div>
                                            </div>
                                            <div className="team__details-right-item-skill-item mb-30">
                                                <div className="team__details-right-item-skill-content">
                                                    <h5>Cleaning</h5>
                                                    <span className="team__details-right-item-skill-count">
                                                        <span className="counter">95</span>%</span>
                                                </div>
                                                <div className="team__details-right-item-skill-inner">
                                                    <div className="team__details-right-item-skill-bar" data-width="95"></div>
                                                </div>
                                            </div>
                                            <div className="team__details-right-item-skill-item">
                                                <div className="team__details-right-item-skill-content">
                                                    <h5>Organization</h5>
                                                    <span className="team__details-right-item-skill-count">
                                                        <span className="counter">90</span>%</span>
                                                </div>
                                                <div className="team__details-right-item-skill-inner">
                                                    <div className="team__details-right-item-skill-bar" data-width="90"></div>
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="team__details-right-item-experience wow fadeInUp" data-wow-delay="0.4s">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="team__details-right-item-experience-image lg-mb-30">
                                                        <img src="/assets/img/team-6.jpg" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="team__details-right-item-experience-content">
                                                        <h3 className="mb-15">We're excellent working Experience our Team</h3>
                                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Team Details Area End --> */}
            </>
        );
    }
}