import React, { Component } from 'react';
import axios from "axios";

import { Row, Col } from "react-bootstrap";

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class PaymentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LoadingFlag: false,
            id: '',
            Payment: {},
        }
    }

    async getDetails(id) {
        console.log("getDetails called ");

        // var id = this.state.id;
        console.log("id=" + id);
        this.setState({ id: id });

        if (id != '') {
            var url = Api_url + `/api/app/event/payment-details`;
            console.log("url=" + url);
            var postData = {
                UserInterface: 1,
                PaymentId: id,
            };
            this.setState({ LoadingFlag: true });
            console.log({ postData });
            await axios
                .post(url, postData, axiosConfig)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    console.log("res=" + JSON.stringify(res));

                    this.setState({ LoadingFlag: false });

                    var flag = res.data.flag;
                    var records = res.data.records;
                    console.log({ flag, records });

                    if (flag) {
                        this.setState({ Payment: records });
                    }

                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
    };

    componentDidMount() {
        console.log("program detail componentDidMount called ");
        window.scrollTo(0, 0);
        let currentLocation = this.props.data;
        currentLocation = currentLocation.toString();
        console.log({ currentLocation });
        let searchParams = currentLocation.split('id=');
        var id = searchParams[1] ? searchParams[1] : '';
        console.log({ searchParams });
        console.log({ id });
        this.getDetails(id);
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3"></div>
                        {/* <div className="col-md-5 col-md-offset-5">
                            <div className="payment-card-content">
                                <div className="payment-card-content-heading"> Payment Details </div>
                                <br />
                                <div className='payment-card-content-text'>
                                    <Row>
                                        <Col lg={4}> <h6>Paid Date </h6> </Col>
                                        <Col lg={8}> <h5> this.state.Payment.CreatedTxt {this.state.Payment.CreatedTxt} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6>Payment Id  </h6> </Col>
                                        <Col lg={8}> <h5> this.state.Payment.PaymentId {this.state.Payment.PaymentId}</h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Order Id</h6> </Col>
                                        <Col lg={8}> <h5> Payment.OrderId {this.state.Payment.OrderId} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Method </h6> </Col>
                                        <Col lg={8}> <h5> this.state.Payment.Method {this.state.Payment.Method} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Paid Amount </h6> </Col>
                                        <Col lg={8}> <h5> this.state.Payment.AmountTxt {this.state.Payment.AmountTxt} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Email </h6> </Col>
                                        <Col lg={8}> <h5> this.state.Payment.Email {this.state.Payment.Email} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Contact </h6> </Col>
                                        <Col lg={8}> <h5> this.state.Payment.Contact {this.state.Payment.Contact} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Description </h6> </Col>
                                        <Col lg={8}> <h5> this.state.Payment.Description {this.state.Payment.Description} </h5> </Col>
                                        <br /> <br />
                                    </Row>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-5 col-md-offset-5">
                            <div className="payment-card-content">
                                <div className="payment-card-content-heading"> Payment Details </div>
                                <br />
                                <div className='payment-card-content-text'>
                                    <Row>
                                        <Col lg={4}> <h6>Paid Date </h6> </Col>
                                        <Col lg={8}> <h5> {this.state.Payment.CreatedTxt} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6>Payment Id  </h6> </Col>
                                        <Col lg={8}> <h5> {this.state.Payment.PaymentId}</h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Order Id</h6> </Col>
                                        <Col lg={8}> <h5> {this.state.Payment.OrderId} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Method </h6> </Col>
                                        <Col lg={8}> <h5> {this.state.Payment.Method} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Paid Amount </h6> </Col>
                                        <Col lg={8}> <h5> {this.state.Payment.AmountTxt} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Email </h6> </Col>
                                        <Col lg={8}> <h5> {this.state.Payment.Email} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Contact </h6> </Col>
                                        <Col lg={8}> <h5> {this.state.Payment.Contact} </h5> </Col>
                                        <br /> <br />
                                        <Col lg={4}> <h6> Description </h6> </Col>
                                        <Col lg={8}> <h5> {this.state.Payment.Description} </h5> </Col>
                                        <br /> <br />
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </>
        )
    }
}
