import React, { Component } from "react";
import axios from 'axios';

import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


const BannerImage1 = process.env.PUBLIC_URL + '/assets/img/banner-1.jpg';
const BannerImage2 = process.env.PUBLIC_URL + '/assets/img/banner-2.jpg';
const BannerImage3 = process.env.PUBLIC_URL + '/assets/img/banner-3.jpg';
console.log("BannerImage1 = ", BannerImage1);
console.log("BannerImage2 = ", BannerImage2);
console.log("BannerImage3 = ", BannerImage3);

SwiperCore.use([Navigation, Scrollbar, A11y, Autoplay, Pagination, EffectFade]);

var Api_url = process.env.REACT_APP_API_URL;


var loading1 = Api_url + `/assets/website/images/loading_3.gif`;
console.log("loading1=" + loading1);

const axiosConfig = {
  headers: {
    "Content-Type": "application/json; text/html; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
};

// const slides = [];

export default class HomeSlider extends Component {
  constructor() {
    super();
    this.state = {
      Records: [],
      // Slides: slides,
      LoadingFlag: false,
      TotalSlides: 0,
    };
  }

  async getAllRecords() {
    console.log("getAllRecords called ");
    var url = Api_url + `/api/app-settings-content`;
    console.log("url=" + url);
    var postData = {
      UserInterface: 1,
      AppName: "website",
      SettingsName: "home_slideshow",
    };
    this.setState({ LoadingFlag: true });
    console.log({ postData });
    // this.setState({ isLoading: true });
    await axios
      .post(url, postData, axiosConfig)
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
        console.log("res=" + JSON.stringify(res));

        var records = res.data.records;
        console.log({ records });
        var result = records.SettingsValues;
        var n1 = Object.keys(result).length;
        console.log({ n1 });
        console.log({ result });

        this.setState({ LoadingFlag: false, Slides: result, TotalSlides: n1 });
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  componentDidMount() {
    console.log("componentDidMount called ");
    this.getAllRecords();
  }

  render() {
    return (
      <>
        {/* <!-- Banner Area Start --> */}
        <div className="banner__area swiper banner-slider mt-0">
          <div className="swiper-wrapper">
            <div className="banner__area swiper-slide">
              <Swiper
                navigation={{
                  nextEl: ".slide-next",
                  prevEl: ".slide-prev",
                }}
                pagination
                spaceBetween={20}
                slidesPerView={1}
                autoplay
                loop
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                <div className="banner__area-swiper-image">
                  {this.state.LoadingFlag
                    ? ""
                    : this.state.TotalSlides > 0
                      ? this.state.Slides.map((item, index) => (
                        <SwiperSlide key={index}>
                          <img width="100%" src={item.FilePath} alt="" />
                          {item.SettingsValuesDescription ? <p style={{ marginTop: '-78px', textAlign: 'center', color: 'white', textShadow: '0 0 5px black', fontFamily: 'Helvetica, sans-serif', fontWeight: 1000 }}>{item.SettingsValuesDescription}</p> : ''}
                        </SwiperSlide>
                      ))
                      : ""}
                </div>
              </Swiper>
            </div>
          </div>
          <div className="swiper-button-prev slide-prev" style={{ color: "white" }}><i className="fas fa-angle-left" style={{ fontSize: "50px" }} ></i></div>
          <div className="swiper-button-next slide-next" style={{ color: "white" }}><i className="fas fa-angle-right" style={{ fontSize: "50px" }}></i></div>
          <div className="banner__area-dots">
            <div className="banner-pagination" />
          </div>
        </div>
        {/* <!-- Banner Area End --> */}
      </>
    );
  }
}