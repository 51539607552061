import React, { Component } from "react";
import SubscribeArea from "../../components/home/SubscribeArea";
import Layout from "../../components/Layout";
import PortfolioDetailsArea from "../../components/portfolio/PortfolioDetailsArea";
import PageHeader from "../../components/service/PageHeader";

export default class PortfolioDetails extends Component {
    componentDidMount(){
        console.log("componentDidMount called");
        window.scrollTo(0,0);
    }
    render() {
        return (
            <>
                <Layout>
                    <PageHeader bannerTitle="Portfolio Details" />
                    <PortfolioDetailsArea />
                    <SubscribeArea />
                </Layout>
            </>
        );
    }
}