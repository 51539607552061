import React, { Component } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

export default class BlogArea extends Component {
    constructor() {
        super();
        this.state = {
            LoadingFlag: false,
            TotalRecords: 0,
            PageNo: 1,
            Records: [],
            hasMore: true,
        };
    }
    async getAllRecords() {
        console.log("getAllRecords called ");

        var PageNo = this.state.PageNo;
        console.log("PageNo=" + PageNo);

        var url = Api_url + `/api/app/events/lists?page=` + PageNo;
        console.log("url=" + url);
        var postData = {
            "UserInterface": 1,
            "FromDate": "2022-09-01",
            "ToDate": "2022-12-27"
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                this.setState({ LoadingFlag: false });

                var flag = res.data.flag;
                var records = res.data.records;
                var TotalPage = records.TotalPage;
                console.log({ records });

                if (flag) {
                    console.log("TotalPage=" + TotalPage + "; PageNo=" + this.state.PageNo + "; hasMore=" + this.state.hasMore);
                    if (TotalPage > this.state.PageNo) {
                        this.setState({ PageNo: this.state.PageNo + 1 });
                    } else {
                        this.setState({ hasMore: false });
                    }

                    var receive_data = records.TableData;
                    var current_data = this.state.Records;
                    var result = current_data.concat(receive_data);
                    var n1 = result.length;
                    console.log("n1=" + n1);

                    this.setState({ Records: result, TotalRecords: n1 });
                } else {
                    this.setState({ hasMore: false });
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };
    componentDidMount() {
        console.log("componentDidMount called");
        this.getAllRecords();
    }

    render() {

        if (this.state.TotalRecords > 0) {
            return (
                <>
                    {/* <!-- Blog Area Start --> */}

                    <div className="blog__area section-padding pt-0">
                        <div className="blog__details section-padding">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 lg-mb-30">
                                        <div className="blog__area-title wow fadeInUp" data-wow-delay="0.0s">
                                            <span className="subtitle-one" style={{fontFamily:"Source Sans Pro"}}>Featured Programs</span>
                                            {/* <span className="subtitle-two">08</span> */}
                                            <h3 className="mb-25" style={{fontFamily:"Play Fair"}}>Checkout our upcoming programs</h3>
                                            <div className="blog__area-dots">
                                                <div className="blog-pagination"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8 lg-mb-30">
                                        <div className="blog__details">
                                            <div className="blog__details-slider swiper blog-details-slider mb-35">
                                                <div className="swiper-wrapper">
                                                    <div className="blog__details-slider-item swiper-slide">
                                                        <Swiper
                                                            navigation
                                                            spaceBetween={50}
                                                            slidesPerView={2}
                                                            autoplay
                                                            loop
                                                            onSlideChange={() => console.log("video slide change")}
                                                            onSwiper={(swiper) => console.log(swiper)}
                                                            breakpoints={{
                                                                0: {
                                                                    slidesPerView: 1,
                                                                },
                                                                576: {
                                                                    slidesPerView: 2,
                                                                },
                                                            }}
                                                        >
                                                            {this.state.Records.map((event, index) => (
                                                                <SwiperSlide key={index}>
                                                                    <div className="blog__area-item-image">
                                                                        {/* <img src={event.EventImagePath} alt={event.EventName} style={{ width: "400px", height: "250px" }} /> */}
                                                                        <img src={event.EventImagePath} alt={event.EventName} style={{ width: "400px", height: "250px" }}/>
                                                                    </div>
                                                                    <div className="blog__area-item-content">
                                                                        <div className="blog__area-item-content-date">
                                                                            <p>{event.DateFromTxt}</p>
                                                                        </div>
                                                                        <h4><Link to={"/event/detail?id=" + event.EventId}>{event.EventName}</Link></h4>
                                                                        <Link to={"/event/detail?id=" + event.EventId}>Details Click here <i className="flaticon-right-arrow"></i></Link>
                                                                        {/* <a href="blog-details.html">Details Click here <i className="flaticon-right-arrow"></i></a> */}
                                                                    </div>
                                                                </SwiperSlide>
                                                            ))}

                                                        </Swiper>
                                                        {/* <img src="assets/img/b-slider-1.jpg" alt="" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Blog Area End --> */}
                </>
            );
        }
    }
}