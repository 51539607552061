import React, { Component } from "react";
// import SubscribeArea from "../../components/home/SubscribeArea";
import Layout from "../../components/Layout";
import PortfolioContent from "../../components/portfolio/PortfolioContent";
import PageHeader from "../../components/service/PageHeader";

export default class Portfolio extends Component {
    componentDidMount(){
        console.log("componentDidMount called");
        window.scrollTo(0,0);
    }
    render() {
        return (
            <>
                <Layout>
                    <PageHeader bannerTitle="Portfolio" />
                    <PortfolioContent />
                    {/* <SubscribeArea /> */}
                </Layout>
            </>
        );
    }
}