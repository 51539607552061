import React, { Component } from "react";
import axios from "axios";
// import PlanCard from "./PlanCard";

import { Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal'

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class EvengtDetailContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            Event: {},
            LoadingFlag: false,
            EventFlag: false,
            ErrorMsg: '',
            Order: {},
            PaymentLoadingFlag: false,
            AllPlans: [],
            Plans: [],
            MemberName: '',
            MobileNumber: '',
            EmailId: '',
            Qty: '',
            TotalAmount: 0,

            ShowModel: false,
            HideModel: false,
            PlanTitle: "",
            PlanDescription: "",
        };
    }

    handleChange(e) {
        // this.setState({ LomId: event.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    calculateAmount(e) {
        console.log('calculateAmount called');
        var qty = parseInt(e.target.value);
        console.log({ qty });
        var allPlans = this.state.AllPlans;
        console.log({ allPlans });
        var newPlans = [];
        allPlans.map((item) => {
            var amount = parseInt(item.Amount);
            var totalAmount = qty * amount;
            item.TotalAmount = isNaN(totalAmount) ? 0 : totalAmount;
            console.log({ item });
            newPlans.push(item);
        });
        this.setState({ Qty: qty, Plans: newPlans });

    }

    submitPlan = async (plan) => {
        console.log("submitPlan called ");
        console.log({ plan });
        var url = Api_url + `/api/app/event/order/create`;
        console.log("url=" + url);

        var postData = {
            UserInterface: 1,
            TransactionType: 1,
            MemberName: this.state.MemberName,
            EmailId: this.state.EmailId,
            MobileNo: this.state.MobileNumber,
            Qty: this.state.Qty,
            Plan: plan.PlanId,
            TotalAmount: plan.TotalAmount,
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ SuccessMsg: "" });
        this.setState({ ErrorMsg: "" });
        // this.setState({ isLoading: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                var flag = res.data.flag;
                console.log("flag=" + flag + ", res=" + JSON.stringify(res));
                if (flag) {
                    var records = res.data.records;
                    console.log({ records });
                    var order = records.Order;
                    console.log({ order });
                    this.setState({ Order: order });
                    this.makePayment(order);
                    // window.location.replace(redirect_url);
                    // this.setState({ SuccessMsg: res.data.output });
                } else {
                    this.setState({ ErrorMsg: res.data.output });
                }
                // this.setState({ isLoading: false });
                // this.fetchData({ value: this.state.zoneId, label: this.state.zoneName });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    makePayment = async (order) => {
        console.log("makePayment called");
        // ev.preventDefault();
        // var order = this.state.Order;
        var back_url = order.back_url;
        console.log("back_url=" + back_url + ", order=" + JSON.stringify(order));
        this.setState({ PaymentLoadingFlag: true });
        const thisIns = this;
        const options = {
            key: order.rzp_key,
            account_id: order.rzp_account_id,
            order_id: order.rzp_order_id,
            amount: order.rzp_amount,
            name: order.rzp_name,
            currency: "INR",
            description: order.rzp_description,
            image: order.rzp_image,
            theme: {
                color: order.rzp_color,
            },
            prefill: {
                name: order.customer_name,
                email: order.customer_email_id,
                contact: order.customer_mobile_no,
            },
            notes: {
                product: order.rzp_product,
                qty: order.rzp_qty,
            },
            // callback_url: "http://domain.com/#/about/" + this.order_id,
            redirect: false,
            handler: function (response) {
                var razorpay_payment_id = response.razorpay_payment_id;
                console.log("razorpay_payment_id=" + razorpay_payment_id);
                var redirect_url = back_url + '?id=' + razorpay_payment_id;
                console.log({ redirect_url });
                thisIns.setState({ PaymentLoadingFlag: false });
                window.location.replace(redirect_url);
                /*
                thisIns.$session.remove("checkout");
                thisIns.SubmitFlag = true;
                thisIns.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                thisIns.pageRedirect({}, "/payment/success");
                */
            },
            "modal": {
                "ondismiss": function () {
                    console.log('Checkout form closed');
                    thisIns.setState({ PaymentLoadingFlag: false });
                }
            }
        };

        const rzp1 = new window.Razorpay(options);
        // const thisIns = this;
        // var rzp1 = new Razorpay();
        rzp1.open();
    };

    async getDetails(id) {
        console.log("getDetails called ");

        // var id = this.state.id;
        console.log("id=" + id);

        if (id > 0) {
            var url = Api_url + `/api/app/events/search`;
            console.log("url=" + url);
            var postData = {
                UserInterface: 1,
                EventCode: id,
            };
            this.setState({ LoadingFlag: true });
            console.log({ postData });
            await axios
                .post(url, postData, axiosConfig)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    console.log("res=" + JSON.stringify(res));

                    this.setState({ LoadingFlag: false });

                    var flag = res.data.flag;
                    var records = res.data.records;
                    console.log({ flag, records });

                    var plans = records.Plans;
                    console.log({ plans });

                    this.setState({ EventFlag: flag, Event: records, AllPlans: plans, Plans: plans });
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
    };

    openModel = (title, description) => {
        console.log("openModel called");
        console.log(title);
        console.log(description);
        this.setState({ PlanTitle: title });
        this.setState({ PlanDescription: description });
        this.setState({ ShowModel: true });
        this.setState({ HideModel: false });
    };
    // openModel(data) {
    //     console.log("openModel called");
    //     console.log({ data });
    //     this.setState({ ShowModel: true });
    //     this.setState({ HideModel: false });
    // }
    closeModel() {
        console.log("closeModel called");
        this.setState({ ShowModel: false });
        this.setState({ HideModel: true });
    }
    componentDidMount() {
        console.log("program detail componentDidMount called ");
        window.scrollTo(0, 0);
        let currentLocation = this.props.data;
        currentLocation = currentLocation.toString();
        console.log({ currentLocation });
        let searchParams = currentLocation.split('id=');
        var id = searchParams[1] ? parseInt(searchParams[1]) : '';
        console.log({ searchParams });
        console.log({ id });
        this.getDetails(id);
    }

    render() {
        return (
            <>
                {/* <!-- Blog Details Area Start --> */}
                <div className="blog__details section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 lg-mb-30">
                                <div className="blog__details-left">
                                    <h2>{this.state.Event.EventName}</h2>
                                    <div className="blog__details-left-meta">
                                        <ul>
                                            <li><a href={onclick = "return: false"}><i className="far fa-user"></i>Incharge By - {this.state.Event.InchargeName}</a></li>
                                            <li><a href={onclick = "return: false"}><i className="flaticon-calendar"></i>{this.state.Event.FromDate} to {this.state.Event.ToDate}</a></li>
                                            <li><a href={onclick = "return: false"}><i className="fas fa-map-marker-alt"></i> {this.state.Event.VenueDetails}</a></li>
                                            {/* <li><a href={onclick = "return: false"}><i className="far fa-comment-dots"></i>3 Comment</a></li> */}
                                        </ul>
                                    </div>
                                    <div className="blog__details-left-slider swiper blog-details-slider mb-35">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12">
                                                <div className="blog__details-left-slider-item swiper-slide">
                                                    <img src={this.state.Event.InvitationPath} style={{ width: 1190, height: 420, objectFit: 'cover' }} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog__details-left-content mb-30">
                                        {/* <p className="mb-20"><b style={{ fontSize: 25 }}>  Venue :- </b>{this.state.Event.VenueDetails}</p> */}
                                        {/* <p className="mb-20"><b style={{ fontSize: 25 }}>Organization Type :- </b>{this.state.Event.OrganizationTypeName}</p> */}
                                        <p className="mb-20 text-justify"><b style={{ fontSize: 25 }}>Content :- </b>{this.state.Event.EventContent} </p>
                                        {/* <p className="text-justify mb-30">{this.state.Event.Description}</p> */}
                                    </div>
                                    {/*
                                    <div className="blog__details-left-video">
                                        <div className="blog__details-left-video-image">
                                            <img src="/assets/img/video.jpg" alt="" />
                                            <div className="blog__details-left-video-image-icon video-pulse">
                                                <a className="video-popup" href="https://www.youtube.com/watch?v=0WC-tD-njcA"><i className="fas fa-play"></i></a>
                                            </div>
                                        </div>
                                        <div className="blog__details-left-video-content">
                                            <h3>Talking Dirty with the Queen of Clean</h3>
                                            <p className="mb-20">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. </p>
                                            <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore </p>
                                        </div>
                                    </div>
                                     */}
                                    <div className="container mt-5">
                                        <div className="row">
                                            <div className="plan-page__form-input-box col-xl-6 col-lg-6 col-md-6 mb-30">
                                                <input
                                                    required
                                                    className="required"
                                                    style={{ width: "100%", height: '50px' }}
                                                    type="text"
                                                    placeholder="Your name"
                                                    value={this.state.MemberName}
                                                    name="MemberName"
                                                    onChange={this.handleChange.bind(this)}
                                                />
                                            </div>
                                            <div className="plan-page__form-input-box col-xl-6 col-lg-6 col-md-6 mb-30">
                                                <input
                                                    required
                                                    className="required"
                                                    style={{ width: "100%", height: '50px' }}
                                                    type="text"
                                                    placeholder="Your mobile number"
                                                    value={this.state.MobileNumber}
                                                    name="MobileNumber"
                                                    onChange={this.handleChange.bind(this)}
                                                />
                                            </div>
                                            <div className="plan-page__form-input-box col-xl-6 col-lg-6 col-md-6 mb-30">
                                                <input
                                                    required
                                                    className="required"
                                                    style={{ width: "100%", height: '50px' }}
                                                    type="text"
                                                    placeholder="Your email id"
                                                    value={this.state.EmailId}
                                                    name="EmailId"
                                                    onChange={this.handleChange.bind(this)}
                                                />
                                            </div>
                                            <div className="plan-page__form-input-box col-xl-6 col-lg-6 col-md-6 mb-30">
                                                <input
                                                    required
                                                    className="required"
                                                    style={{ width: "100%", height: '50px' }}
                                                    type="text"
                                                    placeholder="Qty"
                                                    value={this.state.value}
                                                    name="Qty"
                                                    onChange={this.calculateAmount.bind(this)}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            {this.state.Plans.map((plan, index) => (
                                                <div className="col-xl-4 col-lg-4 col-md-6 mb-30" key={index}>
                                                    <p> {plan.PlanName} </p>
                                                    <p> {plan.Description} </p>
                                                    <p> {plan.AmountTxt} / person </p>
                                                    <p> {plan.TotalAmount} </p>
                                                    <button
                                                        className="theme-btn"
                                                        id="con_submit"
                                                        onClick={() => this.submitPlan(plan)}
                                                    >
                                                        <span>Pay now</span>
                                                    </button>
                                                </div>
                                            ))}
                                        </div> */}

                                        <div className="container mt-5">
                                            <div className="row">
                                                {this.state.Plans.map((plan, index) => (
                                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6" key={index}>
                                                        <div className="plan-card-content">
                                                            <div className="plan-card-content-date">
                                                                {/* <span>{plan.PlanName}</span> */}
                                                                <p>{plan.PlanName}</p>
                                                            </div>
                                                            <div align="center">
                                                                <h2> {plan.TotalAmount} </h2>
                                                                <h5> {plan.AmountTxt} / person </h5>
                                                                <br />
                                                                <p className="btn plan-card-btn1" onClick={() => this.openModel(plan.PlanName, plan.Description)}>Plan Details</p>
                                                                <br />
                                                                <br />
                                                                <hr style={{ color: "black" }} />
                                                                <button
                                                                    className="theme-btn"
                                                                    id="con_submit"
                                                                    onClick={() => this.submitPlan(plan)}
                                                                >
                                                                    <span>Pay now</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <Modal show={this.state.ShowModel} aria-labelledby="contained-modal-title-vcenter" centered>
                                            <Modal.Header>
                                                <Modal.Title> <h5>{this.state.PlanTitle}</h5> </Modal.Title>
                                                <Button variant="secondary" onClick={this.closeModel.bind(this)}>
                                                    X
                                                </Button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {/* <p>{this.state.PlanDescription}</p> */}
                                                <div dangerouslySetInnerHTML={{ __html: this.state.PlanDescription }}></div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="info" onClick={this.closeModel.bind(this)}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <div className="row mb-5">
                                            <div className="col-lg-12 text-center">
                                                <p className="text-success">{this.state.SuccessMsg}</p>
                                                <p className="text-danger">{this.state.ErrorMsg}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Blog Details Area End --> */}
            </>
        );
    }
}