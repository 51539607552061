import React, { Component } from "react";
// import SubscribeArea from "../../components/home/SubscribeArea";
import Layout from "../../components/Layout";
import PageHeader from "../../components/service/PageHeader";
import PastPresidentMemberContent from "../../components/team/PastPresidentMemberContent";
import axios from 'axios';
import { Helmet } from "react-helmet";

var Api_url = process.env.REACT_APP_API_URL;

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class PastPresidentMember extends Component {
    constructor() {
        super();
        this.state = {
            members: [],
            LoadingFlag: false,
            TotalRecords: 0,
        };
    }
    async getRecords() {
        console.log("getRecords called ");
        var url = Api_url + `/api/app/yearwise-team/lists`;
        console.log("url=" + url);
        var postData = {
            "UserInterface": 1,
            "Year": 4,
            "Team": 1
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ LoadingFlag: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records.TableData;
                var n1 = result.length;
                console.log("n1=" + n1);
                console.log("result=" + JSON.stringify(result));
                this.setState({ LoadingFlag: false, TotalRecords: n1, members: result });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        window.scrollTo(0, 0);
        this.getRecords();
    }
    render() {
        return (
            <>
            <Helmet>
                <title>Past-Presidents | Madurai Pandian</title>
                {/* <meta name='description' content='Past-President-Member - Madurai Pandian' /> */}
            </Helmet>
                <Layout>
                    <PageHeader bannerTitle="Past President Member" />
                    {this.state.TotalRecords > 0 ?
                        <PastPresidentMemberContent members={this.state.members} /> : "NA"
                    }
                    {/* <SubscribeArea /> */}
                </Layout>
            </>
        );
    }
}