
import React, { Component } from "react"
import { Link } from "react-router-dom";

export default class ErrorContent extends Component {
    render() {
        return (
            <>
                {/* <!-- Error Area Start --> */}
                <div className="error__area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="error__area-content">
                                    <img src="/assets/img/shape/Error.png" alt="" />
                                    <h2>404. Page not found</h2>
                                    <Link to="/" className="theme-btn">Back to home<i className="flaticon-right-arrow"></i></Link>
                                    {/* <a className="theme-btn" href="index.html">Back to home<i className="flaticon-right-arrow"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Error Area End --> */}
            </>
        );
    }
}