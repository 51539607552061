import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const blockTitleHeart = process.env.PUBLIC_URL + '/assets/images/shapes/heart-2-1.png';

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

var CurrentYear = new Date().getFullYear();

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}
export default class Footer extends Component {
    constructor() {
        super();

        this.state = {
            LoadingFlag: false,
            Blocks: "",
            Logo: "",

            MobileNo: "",
            MailId: "",
            Address: "",
            Mission: "",
            Vision: "",
            Creed: "",
            Facebook: "",
            Twitter: "",
            Instagram: "",
            LinkedIn: "",
            MembersOath: "",
            OfficersOath: "",
            FoundersPerspective: "",
            CompanyName: "",
            PresidentLogo: "",
        };

    }

    async pageDetails() {
        console.log("pageDetails called ");
        var url = Api_url + `/api/app/app-page/show`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            PageName: 'header',
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var flag = res.data.flag;
                var blocks = res.data.records['blocks'];
                var president_logo = res.data.records['president_logo'];

                console.log({ flag, blocks });
                console.log({ president_logo });

                this.setState({ LoadingFlag: false, Blocks: blocks, PresidentLogo: president_logo });

                // var settingsvalues = records.SettingsValues[0].SettingsValuesContent;
                // console.log(settingsvalues);
                var mobile_no = blocks.SettingsValues['mobile_no'].SettingsValuesContent;
                var mail_id = blocks.SettingsValues['mail_id'].SettingsValuesContent;
                var address = blocks.SettingsValues['address'].SettingsValuesContent;
                var mission = blocks.SettingsValues['mission'].SettingsValuesContent;
                var vision = blocks.SettingsValues['vision'].SettingsValuesContent;
                var creed = blocks.SettingsValues['creed'].SettingsValuesContent;
                var facebook = blocks.SettingsValues['facebook'].SettingsValuesContent;
                var twitter = blocks.SettingsValues['twitter'].SettingsValuesContent;
                var instagram = blocks.SettingsValues['instagram'].SettingsValuesContent;
                var linkedin = blocks.SettingsValues['linkedin'].SettingsValuesContent;
                var members_oath = blocks.SettingsValues['members_oath'].SettingsValuesContent;
                var officers_oath = blocks.SettingsValues['officers_oath'].SettingsValuesContent;
                var founders_perspective = blocks.SettingsValues['founders_perspective'].SettingsValuesContent;
                var company_name = blocks.SettingsValues['company_name'].SettingsValuesContent;

                var logo = president_logo.SettingsValues['president_logo'].FilePath;

                this.setState({
                    MobileNo: mobile_no,
                    MailId: mail_id,
                    Address: address,
                    Mission: mission,
                    Vision: vision,
                    Creed: creed,
                    Facebook: facebook,
                    Twitter: twitter,
                    Instagram: instagram,
                    LinkedIn: linkedin,
                    MembersOath: members_oath,
                    OfficersOath: officers_oath,
                    FoundersPerspective: founders_perspective,
                    CompanyName: company_name,
                    Logo: logo,
                });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    componentDidMount() {
        console.log("componentDidMount called ");

        this.pageDetails();
    }
    render() {
        return (
            <>
                {/* <!-- Footer Area Start --> */}
                <div className="footer__area pt-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 md-mb-30">
                                <div className="footer__area-about">
                                    <div className="footer__area-about-widget">
                                        <div className="footer__area-about-logo">
                                            {this.state.Logo !== "" ? <img src={this.state.Logo} width={80} height={80} style={{ borderRadius: "100px" }} alt="" /> : ""}
                                            <h3 className="mt-10 text-center"><div dangerouslySetInnerHTML={{ __html: this.state.CompanyName }} /></h3>
                                        </div>
                                        <div className="footer__area-about-widget-social-icon text-center">
                                            <ul>
                                                <li>
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.Facebook }} />
                                                </li>
                                                <li>
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.Twitter }} />
                                                </li>
                                                <li>
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.Instagram }} />
                                                </li>
                                                <li>
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.LinkedIn }} />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 xl-mb-30">
                                <div className="footer__area-about-widget ml-20">
                                    <h3 style={{ fontFamily: "Source Sans Pro" }}>Our Team</h3>
                                    <div className="footer__area-about-widget-services">
                                        <ul>
                                            <li style={{ fontFamily: "Play Fair" }}><Link to="/team/governing-board-member">Governing Board Member</Link></li>
                                            <li style={{ fontFamily: "Play Fair" }}><Link to="/team/past-president-member">Past President Member</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 md-mb-30">
                                <div className="footer__area-about-widget">
                                    <h3 style={{ fontFamily: "Source Sans Pro" }}>Quick Links</h3>
                                    <div className="footer__area-about-widget-menu">
                                        <div className="row">
                                            <div className="col-12">
                                                <ul>
                                                    <li style={{ fontFamily: "Play Fair" }}><Link to="/about-us">About us</Link></li>
                                                    <li style={{ fontFamily: "Play Fair" }}><Link to="/event/list">Event</Link></li>
                                                    <li style={{ fontFamily: "Play Fair" }}><Link to="/gallery">Gallery</Link></li>
                                                    <li style={{ fontFamily: "Play Fair" }}><Link to="/contact">Contact us</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer__area-contact-info">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 lg-mb-30">
                                    <div className="footer__area-contact-info-title">
                                        <h3 style={{ fontFamily: "Source Sans Pro" }}>Contact US</h3>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-8">
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6 md-mb-30">
                                            <div className="footer__area-contact-info-item">
                                                <div className="footer__area-contact-info-item-icon">
                                                    <i className="flaticon-telephone"></i>
                                                </div>
                                                <div className="footer__area-contact-info-item-content">
                                                    <p style={{ fontFamily: "Source Sans Pro" }}>Tell Us</p>
                                                    <h3 style={{ color: 'wheat' }}>
                                                        {this.state.MobileNo !== "" ? <div dangerouslySetInnerHTML={{ __html: this.state.MobileNo }}></div> : "NA"}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="footer__area-contact-info-item">
                                                <div className="footer__area-contact-info-item-icon">
                                                    <i className="flaticon-email"></i>
                                                </div>
                                                <div className="footer__area-contact-info-item-content">
                                                    <p style={{ fontFamily: "Source Sans Pro" }}>Drop Mail</p>
                                                    <h3 style={{ color: 'wheat' }}>
                                                        {this.state.MailId !== "" ? <div dangerouslySetInnerHTML={{ __html: this.state.MailId }}></div> : "NA"}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Footer Area End --> */}
                {/* <!-- Copyright Area Start --> */}
                <div className="copyright__area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="copyright__area-title t-center">
                                    <p>© {CurrentYear} Powered by EZCLUB. &nbsp; Made with <img src={blockTitleHeart} style={{ width: 15 }} alt="" /> <a href="https://vaagai.org.in" target="_blank" rel="noopener noreferrer">Vaagai Tecknowledge</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Copyright Area End --> */}
            </>
        );
    }
}