import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class AboutArea extends Component {
    render() {
        return (
            <>
                {/* <!-- About Area Start --> */}
                <div className="about__area-two section-padding">
                    <div className="about__area-title-right-image" data-background="/assets/img/shape/about-bg.png"></div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 lg-mb-30">
                                <div className="about__area-two-left mr-70 wow fadeInUp" data-wow-delay="0.4s">
                                    <div className="about__area-two-left-image">
                                        {/* President Image */}
                                        {this.props.PresidentImage !== "" ? <img src={this.props.PresidentImage} className="choose-img_one" style={{ width: 400, height: 500 }} alt="" /> : ""}
                                        <div className="about__area-two-left-image-small-img">
                                            {/* President Logo */}
                                            {this.props.PresidentLogo !== "" ? <img src={this.props.PresidentLogo} className="choose-img_two" style={{ width: 250, height: 250 }} alt="" /> : ""}
                                        </div>
                                        <div className="about__area-two-left-image-content">
                                            <p><span className="counter">23</span>+</p>
                                            <p>Years Experience</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="about__area-title-right wow fadeInUp" data-wow-delay="0.8s">
                                    <span className="subtitle-one" style={{ fontFamily: "Source Sans Pro" }}>Company about</span>
                                    {/* <span className="subtitle-two">01</span> */}
                                    {this.props.PresidentMessage !== "" ? <div className="text-justify mb-40" style={{ overflow: "hidden", height: "19em" }} dangerouslySetInnerHTML={{ __html: this.props.PresidentMessage }}></div> : "Comming Soon"}
                                    {/* <a className="theme-btn" href={onclick = "return: false"}>Learn More<i className="flaticon-right-arrow"></i></a> */}
                                    <Link to="/about-jci" className="theme-btn" >Learn More<i className="flaticon-right-arrow"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- About Area End --> */}
            </>
        );
    }
}