import React, { Component } from "react";
import axios from "axios";

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
}

export default class ContactArea extends Component {
    constructor() {
        super();
        this.state = {
            LoadingFlag: false,
            Blocks: "",

            MobileNo: "",
            MailId: "",
            Address: "",
            Mission: "",
            Vision: "",
            Creed: "",
            Facebook: "",
            Twitter: "",
            Instagram: "",
            LinkedIn: "",
            MembersOath: "",
            OfficersOath: "",
            FoundersPerspective: "",
            CompanyName: "",
        };
    }

    async pageDetails() {
        console.log("pageDetails called ");
        var url = Api_url + `/api/app/app-page/show`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            PageName: 'blocks',
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var flag = res.data.flag;
                var blocks = res.data.records['blocks'];

                console.log({ flag, blocks });

                this.setState({ LoadingFlag: false, Blocks: blocks });

                // var settingsvalues = records.SettingsValues[0].SettingsValuesContent;
                // console.log(settingsvalues);
                var mobile_no = blocks.SettingsValues['mobile_no'].SettingsValuesContent;
                var mail_id = blocks.SettingsValues['mail_id'].SettingsValuesContent;
                var address = blocks.SettingsValues['address'].SettingsValuesContent;
                var mission = blocks.SettingsValues['mission'].SettingsValuesContent;
                var vision = blocks.SettingsValues['vision'].SettingsValuesContent;
                var creed = blocks.SettingsValues['creed'].SettingsValuesContent;
                var facebook = blocks.SettingsValues['facebook'].SettingsValuesContent;
                var twitter = blocks.SettingsValues['twitter'].SettingsValuesContent;
                var instagram = blocks.SettingsValues['instagram'].SettingsValuesContent;
                var linkedin = blocks.SettingsValues['linkedin'].SettingsValuesContent;
                var members_oath = blocks.SettingsValues['members_oath'].SettingsValuesContent;
                var officers_oath = blocks.SettingsValues['officers_oath'].SettingsValuesContent;
                var founders_perspective = blocks.SettingsValues['founders_perspective'].SettingsValuesContent;
                var company_name = blocks.SettingsValues['company_name'].SettingsValuesContent;

                this.setState({
                    MobileNo: mobile_no,
                    MailId: mail_id,
                    Address: address,
                    Mission: mission,
                    Vision: vision,
                    Creed: creed,
                    Facebook: facebook,
                    Twitter: twitter,
                    Instagram: instagram,
                    LinkedIn: linkedin,
                    MembersOath: members_oath,
                    OfficersOath: officers_oath,
                    FoundersPerspective: founders_perspective,
                    CompanyName: company_name,
                });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });

    };

    componentDidMount() {
        console.log("ContactArea componentDidMount called ");
        this.pageDetails();
    }

    render() {
        return (
            <>
                {/* <!-- Contact Area Start --> */}
                <div className="contact__list pb-100 section-padding">
                    <div className="container pb-100">
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 xl-mb-30">
                                <div className="contact__list-item wow fadeInUp" data-wow-delay="0.4s">
                                    <div className="contact__list-item-icon">
                                        <i className="flaticon-telephone"></i>
                                    </div>
                                    <div className="contact__list-item-content">
                                        <h4>Tell Us</h4>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.MobileNo }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 lg-mb-30">
                                <div className="contact__list-item wow fadeInUp" data-wow-delay="0.8s">
                                    <div className="contact__list-item-icon">
                                        <i className="flaticon-email"></i>
                                    </div>
                                    <div className="contact__list-item-content">
                                        <h4>Drop Mail</h4>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.MailId }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6">
                                <div className="contact__list-item wow fadeInUp" data-wow-delay="1.2s">
                                    <div className="contact__list-item-icon">
                                        <i className="flaticon-location"></i>
                                    </div>
                                    <div className="contact__list-item-content">
                                        <h4>Location</h4>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.Address }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Contact Area End --> */}
            </>
        );
    }
}