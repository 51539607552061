import React, { Component } from "react";

const BannerImage1 = process.env.PUBLIC_URL + '/assets/img/page.jpg';

export default class PageHeader extends Component {
    render() {
        return (
            <>
                {/* <!-- Page Header Start Start --> */}
                {/* <div className="page__banner" data-background="/assets/img/page.jpg"> */}
                <div className="page__banner" style={{ backgroundImage: `url(${BannerImage1})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="page__banner-content">
                                    <h2>{this.props.bannerTitle}</h2>
                                    <ul>
                                        <li><a href="index.html">Home</a><i className="fas fa-chevron-right"></i></li>
                                        <li>{this.props.bannerTitle}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Page Header Area End --> */}
            </>
        );
    }
}