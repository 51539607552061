import React, { Component } from "react";

export default class ServiceDetailsArea extends Component {
    render() {
        return (
            <>
                {/* <!-- Services Details Area Start --> */}
                <div className="services__details section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-8">
                                <div className="services__details-left">
                                    <div className="services__details-left-image mb-25">
                                        <img src="/assets/img/services.jpg" alt="" />
                                    </div>
                                    <div className="services__details-left-content">
                                        <h2 className="mb-20">Kitchen Cleaning</h2>
                                        <p className="mb-20">Pretium convallis lacinia. Quisque ac eros urna. Mauris eu molestie odio. Nullam id consectetur nibh, at luctus elit. Sed ipsum nunc, elementum vitae suscipit sit amet, malesuada in turpis. Vivamus vel augue congue, maximus tortor sed, malesuada urna. Nunc dictum diam eros. Nulla sit amet interdum tellus, a hendrerit dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <p className="mb-40">pretium convallis lacinia. Quisque ac eros urna. Mauris eu molestie odio. Nullam id consectetur nibh, at luctus elit. Sed ipsum nunc, elementum vitae suscipit sit amet, malesuada in turpis. Vivamus vel augue congue, maximus tortor sed, malesuada urna. Nunc dictum diam eros. Nulla sit amet interdum tellus, a hendrerit dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div className="services__details-left-content-list">
                                            <p className="mb-20"><i className="flaticon-check-1"></i>Experience and reputation - cleaning companies who have a long-standing reputation tend to charge a premium for their cleaning services.</p>
                                            <p className="mb-20"><i className="flaticon-check-1"></i>Frequency of Cleaning - the more you clean, the less you are charged by the cleaning companies because the house will require less cleaning with subsequent visits</p>
                                            <p className="mb-40"><i className="flaticon-check-1"></i>Location of your home or office - the further the distance the cleaner has to travel to get to your home the higher the cost. That is why it is crucial to hire a cleaning service closer to you.</p>
                                        </div>
                                        <div className="row mb-40">
                                            <div className="col-md-4 pr-0 md-pl-0 md-mb-20">
                                                <div className="chooseus__area-right-icon-list  wow fadeInUp" data-wow-delay="0.4s">	<i className="flaticon-spray"></i>
                                                    <p>Reliable Through<br/>Experience</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 p-0 md-mb-20 m">
                                                <div className="chooseus__area-right-icon-list center  wow fadeInUp" data-wow-delay="0.8s">	<i className="flaticon-customer-service"></i>
                                                    <p>Customizable<br/>Services</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 pl-0 md-pr-0">
                                                <div className="chooseus__area-right-icon-list  wow fadeInUp" data-wow-delay="1.2s">	<i className="flaticon-online-booking"></i>
                                                    <p>Booking Online<br/>Appointment</p>
                                                </div>
                                            </div>
                                        </div>
                                        <h3 className="mb-20">Making your home look its best</h3>
                                        <p>Pretium convallis lacinia. Quisque ac eros urna. Mauris eu molestie odio. Nullam id consectetur nibh, at luctus elit. Sed ipsum nunc, elementum vitae suscipit sit amet, malesuada in turpis. Vivamus vel augue congue, maximus tortor sed, malesuada urna. Nunc dictum diam eros. Nulla sit amet interdum tellus, a hendrerit dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="all__sidebar">
                                    <div className="all__sidebar-item mb-30  wow fadeInUp" data-wow-delay="0.4s">
                                        <h3 className="mb-20">Category</h3>
                                        <div className="all__sidebar-item-category">
                                            <ul>
                                                <li><a href="blog-details.html">Road Cleaning <i className="fas fa-chevron-right"></i></a></li>
                                                <li><a href="blog-details.html">Kitchen Cleaning <i className="fas fa-chevron-right"></i></a></li>
                                                <li><a href="blog-details.html">House Cleaning <i className="fas fa-chevron-right"></i></a></li>
                                                <li><a href="blog-details.html">Office Cleaning <i className="fas fa-chevron-right"></i></a></li>
                                                <li><a href="blog-details.html">Freeze Cleaning <i className="fas fa-chevron-right"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="all__sidebar-item mb-30  wow fadeInUp" data-wow-delay="0.4s">
                                        <h3 className="mb-20">Online Quote</h3>
                                        <div className="all__sidebar-item-quote">
                                            <form action="#">
                                                <div className="row">
                                                    <div className="col-sm-12 mb-30">
                                                        <div className="contact__form-right-form-item">
                                                            <i className="far fa-user"></i>
                                                            <input type="text" name="name" placeholder="Your Name" required="required" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 mb-30">
                                                        <div className="contact__form-right-form-item">
                                                            <i className="far fa-envelope-open"></i>
                                                            <input type="text" name="email" placeholder="Email Address" required="required" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 mb-30">
                                                        <div className="contact__form-right-form-item">
                                                            <textarea name="message" rows="5" placeholder="Message"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="contact__form-right-form-item">
                                                            <button className="theme-btn" type="submit">Submit Now<i className="flaticon-right-arrow"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Services Details Area End --> */}
            </>
        );
    }
}