import React, { Component } from "react";
import axios from "axios";
import 'lightbox-react/style.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

export default class TeamArea extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            Slides: [],
            LoadingFlag: false,
            TotalSlides: 0,
            FilePath: '',
        };
    }

    async getAllRecords() {
        console.log("getAllRecords called ");
        var url = Api_url + `/api/app/yearwise-team/lists`;
        console.log("url=" + url);
        var postData = {
            "UserInterface": 1,
            "Year": 4,
            "Team": 2
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ LoadingFlag: true });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records.TableData;
                var n1 = result.length;
                console.log("n1=" + n1);
                console.log("result=" + JSON.stringify(result));
                this.setState({ LoadingFlag: false, TotalSlides: n1, Slides: result });

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        this.getAllRecords();
    }
    render() {
        return (
            <>
                {/* <!-- Team Area Start --> */}
                <div className="team__area section-padding">
                    <div className="auto-container">
                        <div className="row mb-60">
                            <div className="col-xl-12">
                                <div className="team__area-title">
                                    <span className="subtitle-one" style={{ fontFamily: "Source Sans Pro" }}>Our Team </span>
                                    {/* <span className="subtitle-two">06</span> */}
                                    <h2 style={{fontFamily:"Play Fair"}}>Members of our Organization</h2>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-sm-3 col-md-4 col-xl-12 col-lg-12 lg-mb-30">
                                    <div className="blog__details">
                                        <div className="blog__details-slider swiper blog-details-slider mb-35">
                                            <div className="swiper-wrapper">
                                                <div className="blog__details-slider-item swiper-slide">
                                                    <Swiper
                                                        navigation
                                                        spaceBetween={50}
                                                        slidesPerView={3}
                                                        autoplay
                                                        loop
                                                        onSlideChange={() => console.log("video slide change")}
                                                        onSwiper={(swiper) => console.log(swiper)}
                                                        breakpoints={{
                                                            0: {
                                                                slidesPerView: 1,
                                                            },
                                                            376: {
                                                                slidesPerView: 2,
                                                            },
                                                            654: {
                                                                slidesPerView: 3,
                                                            }
                                                        }}
                                                    >
                                                        {this.state.Slides.map((item, index) => (
                                                            <SwiperSlide key={index}>
                                                                <div className="blog__area-item-image">
                                                                    <img src={item.ProfilePic} alt="" style={{ width: "400px", height: "250px" }} />
                                                                </div>
                                                                <div className="blog__area-item-content text-center">
                                                                    <br />
                                                                    <p><Link to={"/team/profile?id=" + item.MemberId}>{item.DesignationName}</Link></p>
                                                                    <p><Link to={"/team/profile?id=" + item.MemberId}>{item.MemberName}</Link></p>
                                                                    <Link to={"/team/profile?id=" + item.MemberId}>Details Click here <i className="flaticon-right-arrow"></i></Link>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Team Area End --> */}
            </>
        );
    }
}