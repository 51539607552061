import React, { Component } from "react";

export default class ServiceFAQArea extends Component {
    render() {
        return (
            <>
                <div className="faq__area section-padding pb-0" data-background="assets/img/shape/map.png">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 lg-mb-70">
                                <div className="faq__area-left mr-70">
                                    <div className="faq__area-left-image wow fadeInUp" data-wow-delay="0.4s">
                                        <img src="assets/img/faq.jpg" alt="" />
                                        <div className="faq__area-left-image-contact">
                                            <div className="faq__area-left-image-contact-icon">	<i className="flaticon-telephone"></i>
                                            </div>
                                            <div className="faq__area-left-image-contact-text">
                                                <p>Tell Us</p>
                                                <h3><a href="tel:+12)586898257">+12) 586 898 257</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="faq__area-right wow fadeInUp" data-wow-delay="0.8s">
                                    <div className="faq__area-right-title mb-35">	<span className="subtitle-one">FAQ</span>
                                        <span className="subtitle-two">04</span>
                                        <h2>Get clear solution answer FAQ.</h2>
                                    </div>
                                    <div className="faq__area-collapse">
                                        <div className="faq__area-collapse-item">
                                            <div className="faq__area-collapse-item-card">
                                                <div className="faq__area-collapse-item-card-header">
                                                    <h4>01. What do you clean before cleaning?</h4>
                                                    <i className="fas fa-minus"></i>
                                                </div>
                                                <div className="faq__area-collapse-item-card-header-content active">
                                                    <p>Nullam at odio dictum, tempus nibh vel, gravida purus. Nunc placerat, sapien ut malesuada tristique, nulla felis cursus nulla, ut pretium diam nunc eu turpis.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="faq__area-collapse-item">
                                            <div className="faq__area-collapse-item-card">
                                                <div className="faq__area-collapse-item-card-header">
                                                    <h4>02. How much is a cleaner per hour?</h4>
                                                    <i className="fas fa-plus"></i>
                                                </div>
                                                <div className="faq__area-collapse-item-card-header-content" style={{ display : "none" }}>
                                                    <p>Nullam at odio dictum, tempus nibh vel, gravida purus. Nunc placerat, sapien ut malesuada tristique, nulla felis cursus nulla, ut pretium diam nunc eu turpis.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="faq__area-collapse-item m-0">
                                            <div className="faq__area-collapse-item-card">
                                                <div className="faq__area-collapse-item-card-header">
                                                    <h4>03. Do you pay cleaners before or after?</h4>
                                                    <i className="fas fa-plus"></i>
                                                </div>
                                                <div className="faq__area-collapse-item-card-header-content" style={{ display : "none" }}>
                                                    <p>Nullam at odio dictum, tempus nibh vel, gravida purus. Nunc placerat, sapien ut malesuada tristique, nulla felis cursus nulla, ut pretium diam nunc eu turpis.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Applying Area Start --> */}
                        <div className="faq__area-applying wow fadeInUp" data-wow-delay="0.5s">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-7 lg-mb-30">
                                    <div className="faq__area-applying-left">
                                        <h2 className="mb-30">Applying the modern concept for cleaning services.</h2>
                                        <a className="theme-banner-btn2" href="services.html">Our Services<i className="flaticon-right-arrow"></i></a>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-5">
                                    <div className="faq__area-applying-right">
                                        <div className="faq__area-applying-right-image">
                                            <img src="assets/img/applying.jpg" alt="" />
                                            <div className="faq__area-applying-right-image-content">
                                                <p><span className="counter">119</span>K</p>
                                                <p>Clients Satisfied</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Applying Area End --> */}
                    </div>
                </div></>
        );
    }
}