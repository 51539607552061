import React, { Component } from "react";

export default class AboutJciContent extends Component {
    render() {
        return (
            <>
                <div className="about__area-two section-padding">
                    <div className="container pb-100">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6">
                                <div className="about__area-two-left mr-70 wow fadeInUp">
                                    <div className="about__area-two-left-image">
                                        <img src="/assets/images/about/founder.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="about__area-title-right wow fadeInUp">
                                    <span className="subtitle-one" style={{fontFamily:"Source Sans Pro"}}>Henry Giessenbier Founder</span>
                                    <p className="mb-20"  style={{fontFamily:"Play Fair"}}>"From within the walls of the soul of this organization wherein the foundation of character and good citizenship are laid, I hope a message will come in the sometime of tomorrow that will stir the people towards the establishment of a permanent and everlasting world peace."</p>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center mt-30">
                            <div className="col-xl-6 col-lg-6">
                                <div className="about__area-two-left mr-70 wow fadeInUp">
                                    <div className="about__area-two-left-image">
                                        <img src="/assets/images/about/about-1.jpg" alt="" />
                                    </div>
                                    <p></p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 mb-20">
                                <div className="about__area-title-right wow fadeInUp">
                                    <span className="subtitle-one" style={{fontFamily:"Source Sans Pro"}}>About JCI</span>
                                    <p className="mb-20"  style={{fontFamily:"Play Fair"}}>
                                        Junior Chamber International (JCI) is a worldwide federation of young leaders and entrepreneurs with nearly five lakh active members and millions of alumni spread across more than 115 countries.
                                    </p>
                                    <p className="mb-20"  style={{fontFamily:"Play Fair"}}> <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> JCI orgin can be traced back to 1915 to the city of St. Louis, Missouri, USA where Sir Henry Giessenbier with 32 other young men established Young Men's Progressive Civic Association (YMPCA) </p>
                                    <p className="mb-20"  style={{fontFamily:"Play Fair"}}> <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> In 1916, the name was changed from YMPCA to "Junior Citizens", commonly called "JCs", which later became "Jaycees"
                                    </p>
                                    <p className="mb-20"  style={{fontFamily:"Play Fair"}}> <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> Their work so impressed the St. Louis Junior chamber of Commerce that they asked these young men in 1918 to adopt the name “Junior Chamber of Commerce” which later became “Junior Chamber”
                                    </p>
                                    <p className="mb-20"  style={{fontFamily:"Play Fair"}}> <i className='fab fa-diaspora' style={{color:"#1D7773"}}></i> By 1944, the movement had spread through 18 countries officially forming "Junior Chamber International", welcoming people between age 18 and 40 years.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
