import React, { Component } from "react";
import { Helmet } from "react-helmet";
import AboutJciContent from "../../components/about-us/AboutJciContent";
import Layout from "../../components/Layout";
import PageHeader from "../../components/service/PageHeader";

export default class AboutJci extends Component{
    componentDidMount(){
        console.log("componentDidMount called");
        window.scrollTo(0,0);
    }
    render(){
        return(
            <>
            <Helmet>
                <title>About JCI | Madurai Pandian</title>
                {/* <meta name='description' content='AboutUs - Madurai Pandian' /> */}
            </Helmet>
            <Layout>
                <PageHeader bannerTitle="About JCI"/>
                <AboutJciContent/>
            </Layout>
            </>
        );
    }
}