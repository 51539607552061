import React, { Component } from "react";

const Image1= process.env.PUBLIC_URL + '/assets/img/portfolio-4.jpg';

export default class PortfolioDetailsArea extends Component {
    render() {
        return (
            <>
                {/* <!-- Portfolio Area Start --> */}
                <div className="portfolio__details section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="portfolio__details-area">
                                    {/* <div className="portfolio__details-area-image" data-background="/assets/img/portfolio-4.jpg"> */}
                                    <div className="portfolio__details-area-image" style={{ backgroundImage: `url(${Image1})` }}>
                                        <div className="portfolio__details-area-image-list">
                                            <div className="portfolio__details-area-image-list-item">
                                                <div className="portfolio__details-area-image-list-item-icon">
                                                    <i className="far fa-user"></i>
                                                </div>
                                                <div className="portfolio__details-area-image-list-item-content">
                                                    <span>Client :</span>
                                                    <p>Road Freight Transportation</p>
                                                </div>
                                            </div>
                                            <div className="portfolio__details-area-image-list-item">
                                                <div className="portfolio__details-area-image-list-item-icon">
                                                    <i className="flaticon-price-tag"></i>
                                                </div>
                                                <div className="portfolio__details-area-image-list-item-content">
                                                    <span>Category :</span>
                                                    <p>Office Cleaning Services</p>
                                                </div>
                                            </div>
                                            <div className="portfolio__details-area-image-list-item">
                                                <div className="portfolio__details-area-image-list-item-icon">
                                                    <i className="flaticon-calendar"></i>
                                                </div>
                                                <div className="portfolio__details-area-image-list-item-content">
                                                    <span>Date :</span>
                                                    <p>22 March, 2022</p>
                                                </div>
                                            </div>
                                            <div className="portfolio__details-area-image-list-item">
                                                <div className="portfolio__details-area-image-list-item-social">
                                                    <ul>
                                                        <li><a href={onclick="return: false"}><i className="fab fa-facebook-f"></i></a>
                                                        </li>
                                                        <li><a href={onclick="return: false"}><i className="fab fa-twitter"></i></a>
                                                        </li>
                                                        <li><a href={onclick="return: false"}><i className="fab fa-linkedin-in"></i></a>
                                                        </li>
                                                        <li><a href={onclick="return: false"}><i className="fab fa-pinterest-p"></i></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio__details-area-content mt-30">
                                        <h2 className="mb-20">Office Cleaning</h2>
                                        <p className="mb-20">Pretium convallis lacinia. Quisque ac eros urna. Mauris eu molestie odio. Nullam id consectetur nibh, at luctus elit. Sed ipsum nunc, elementum vitae suscipit sit amet, malesuada in turpis. Vivamus vel augue congue, maximus tortor sed, malesuada urna. Nunc dictum diam eros. Nulla sit amet interdum tellus, a hendrerit dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <p className="mb-40">Pretium convallis lacinia. Quisque ac eros urna. Mauris eu molestie odio. Nullam id consectetur nibh, at luctus elit. Sed ipsum nunc, elementum vitae suscipit sit amet, malesuada in turpis. Vivamus vel augue congue, maximus tortor sed, malesuada urna. Nunc dictum diam eros. Nulla sit amet interdum tellus, a hendrerit dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div className="portfolio__details-area-content-list">
                                            <p className="mb-20"><i className="flaticon-check-1"></i>Experience and reputation - cleaning companies who have a long-standing reputation tend to charge a premium for their cleaning services.</p>
                                            <p className="mb-20"><i className="flaticon-check-1"></i>Frequency of Cleaning - the more you clean, the less you are charged by the cleaning companies because the house will require less cleaning with subsequent visits</p>
                                            <p className="mb-40"><i className="flaticon-check-1"></i>Location of your home or office - the further the distance the cleaner has to travel to get to your home the higher the cost. That is why it is crucial to hire a cleaning service closer to you.</p>
                                        </div>
                                        <div className="row mb-35">
                                            <div className="col-sm-6 sm-mb-30">
                                                <div className="portfolio__details-area-content-image">
                                                    <img src="/assets/img/blog-9.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="portfolio__details-area-content-image">
                                                    <img src="/assets/img/blog-3.jpg" alt="" />
                                                    <div className="portfolio__details-area-content-image-video-icon video-pulse">
                                                        <a className="video-popup" href="https://www.youtube.com/watch?v=0WC-tD-njcA"><i className="fas fa-play"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h3 className="mb-20">Making your home look its best</h3>
                                        <p>Pretium convallis lacinia. Quisque ac eros urna. Mauris eu molestie odio. Nullam id consectetur nibh, at luctus elit. Sed ipsum nunc, elementum vitae suscipit sit amet, malesuada in turpis. Vivamus vel augue congue, maximus tortor sed, malesuada urna. Nunc dictum diam eros. Nulla sit amet interdum tellus, a hendrerit dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Portfolio Area End --> */}
            </>
        );
    }
}