import React, { Component } from "react";
import SubscribeArea from "../../components/home/SubscribeArea";
import Layout from "../../components/Layout";
import PageHeader from "../../components/service/PageHeader";
import ServiceDetailsArea from "../../components/service/ServiceDetailsArea";

export default class ServiceDetails extends Component {
    componentDidMount(){
        console.log("componentDidMount called");
        window.scrollTo(0,0);
    }
    render() {
        return (
            <>
                <Layout>
                    <PageHeader bannerTitle="Service Details" />
                    <ServiceDetailsArea />
                    <SubscribeArea />
                </Layout>
            </>
        );
    }
}