import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route } from "react-router-dom";
import Event from './pages/event/Event';
import EventDetail from './pages/event/EventDetail';
import Contact from './pages/Contact';
import Error from './pages/Error';
import Home from './pages/Home';
import Portfolio from './pages/portfolio/Portfolio';
import PortfolioDetails from './pages/portfolio/PortfolioDetails';
import Service from './pages/service/Service';
import ServiceDetails from './pages/service/ServiceDetails';
import Gallery from './pages/Gallery';
import GoverningBoardMember from './pages/team/GoverningBoardMember';
import PastPresidentMember from './pages/team/PastPresidentMember';
import TeamProfile from './pages/team/TeamProfile';
import Members from './pages/team/Members';
import AboutJci from './pages/about/AboutJci';
import AboutJciMaduraiPandian from './pages/about/AboutJciMaduraiPandian';
import Bulletin from './pages/Bulletin';
import PaymentSuccess from './pages/PaymentSuccess';

export default function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route path='service' element={<Service />}></Route>
          <Route path='service/service-details' element={<ServiceDetails />}></Route>
          <Route path='portfolio' element={<Portfolio />}></Route>
          <Route path='portfolio/portfolio-details' element={<PortfolioDetails />}></Route>
          <Route path='event/list' element={<Event />}></Route>
          <Route path='event/detail' element={<EventDetail />}></Route>
          <Route path='team/governing-board-members' element={<GoverningBoardMember />}></Route>
          <Route path='team/past-presidents' element={<PastPresidentMember />}></Route>
          <Route path='team/members' element={<Members />}></Route>
          <Route path='team/profile' element={<TeamProfile />}></Route>
          <Route path='gallery' element={<Gallery />}></Route>
          <Route path='about-jci' element={<AboutJci />}></Route>
          <Route path='about-jci-madurai-pandian' element={<AboutJciMaduraiPandian />}></Route>
          <Route path='bulletin' element={<Bulletin />}></Route>
          <Route path='contact' element={<Contact />}></Route>
          <Route path='payment-success' element={<PaymentSuccess />}></Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </HashRouter>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
