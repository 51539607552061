import React, { Component } from "react";

export default class SubscribeArea extends Component{
    render(){
        return(
            <>
            {/* <!-- Subscribe Area Start --> */}
            <div className="subscribe__area">
                <div className="container">
                    <div className="row align-items-center subscribe-p">
                        <div className="col-xl-6 col-lg-6 lg-mb-30">
                            <div className="subscribe__area-left">
                                <div className="subscribe__area-left-shape">
                                    <img src="/assets/img/shape/subscribe.png" alt="" />
                                </div>
                                <h2>Great email newsletters Just for you</h2>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="subscribe__area-right">
                                <form action="#">	<i className="far fa-envelope-open"></i>
                                    <input type="text" name="email" placeholder="Your Email" required="required" />
                                        <button className="theme-banner-btn" type="submit" name="button">Submit Now</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Subscribe Area End --> */}
            </>
        );
    }
}