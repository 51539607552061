import React, { Component } from "react";

export default class ServiceContent extends Component {
    render() {
        return (
            <>
                <div className="services__area section-padding pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                                <div className="services__area-item wow fadeInUp" data-wow-delay="0.4s">	<span>01</span>
                                    <div className="services__area-item-icon mb-25">	<i className="flaticon-house"></i>
                                    </div>
                                    <div className="services__area-item-content">
                                        <h3 className="mb-25"><a href="services-details.html">House Cleaning</a></h3>
                                        <p className="mb-20">Phasellus dapibus nulla eget vulputate consectetur. Maecenas urna</p>
                                    </div>	<a href="services-details.html">Read More <i className="flaticon-next"></i></a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 md-mb-30">
                                <div className="services__area-item wow fadeInUp" data-wow-delay="0.8s">	<span>02</span>
                                    <div className="services__area-item-icon mb-25">	<i className="flaticon-basket"></i>
                                    </div>
                                    <div className="services__area-item-content">
                                        <h3 className="mb-25"><a href="services-details.html">Office Cleaning</a></h3>
                                        <p className="mb-20">Phasellus dapibus nulla eget vulputate consectetur. Maecenas urna</p>
                                    </div>	<a href="services-details.html">Read More <i className="flaticon-next"></i></a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 lg-mb-30">
                                <div className="services__area-item wow fadeInUp" data-wow-delay="1.2s">	<span>03</span>
                                    <div className="services__area-item-icon mb-25">	<i className="flaticon-kitchen"></i>
                                    </div>
                                    <div className="services__area-item-content">
                                        <h3 className="mb-25"><a href="services-details.html">Kitchen Cleaning</a></h3>
                                        <p className="mb-20">Phasellus dapibus nulla eget vulputate consectetur. Maecenas urna</p>
                                    </div>	<a href="services-details.html">Read More <i className="flaticon-next"></i></a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 md-mb-30">
                                <div className="services__area-item wow fadeInUp" data-wow-delay="1.4s">	<span>04</span>
                                    <div className="services__area-item-icon mb-25">	<i className="flaticon-toilet"></i>
                                    </div>
                                    <div className="services__area-item-content">
                                        <h3 className="mb-25"><a href="services-details.html">Washroom Cleaning</a></h3>
                                        <p className="mb-20">Phasellus dapibus nulla eget vulputate consectetur. Maecenas urna</p>
                                    </div>	<a href="services-details.html">Read More <i className="flaticon-next"></i></a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 md-mb-30">
                                <div className="services__area-item wow fadeInUp" data-wow-delay="1.8s">	<span>04</span>
                                    <div className="services__area-item-icon mb-25">	<i className="flaticon-carpet"></i>
                                    </div>
                                    <div className="services__area-item-content">
                                        <h3 className="mb-25"><a href="services-details.html">Carpet Cleaning</a></h3>
                                        <p className="mb-20">Phasellus dapibus nulla eget vulputate consectetur. Maecenas urna</p>
                                    </div>	<a href="services-details.html">Read More <i className="flaticon-next"></i></a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="services__area-item wow fadeInUp" data-wow-delay="2.2s">	<span>04</span>
                                    <div className="services__area-item-icon mb-25">	<i className="flaticon-tank"></i>
                                    </div>
                                    <div className="services__area-item-content">
                                        <h3 className="mb-25"><a href="services-details.html">Water Tank Cleaning</a></h3>
                                        <p className="mb-20">Phasellus dapibus nulla eget vulputate consectetur. Maecenas urna</p>
                                    </div>	<a href="services-details.html">Read More <i className="flaticon-next"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}