import React, { Component } from "react";

export default class PortfolioContent extends Component {
    render() {
        return (
            <>
                <div className="portfolio__area section-padding">
                    <div className="container">
                        <div className="row mb-60">
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                                <div className="portfolio__area-item wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="portfolio__area-item-image">
                                        <img src="/assets/img/portfolio-1.jpg" alt="" />
                                        <div className="portfolio__area-item-image-icon">	<a href="portfolio-details.html"><i className="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                    <div className="portfolio__area-item-content">
                                        <h3><a href="portfolio-details.html">Hours Cleaning</a></h3>
                                        <p>Cleaning Services</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 md-mb-30">
                                <div className="portfolio__area-item portfolio__area-item-hover wow fadeInUp" data-wow-delay="0.6s">
                                    <div className="portfolio__area-item-image">
                                        <img src="/assets/img/portfolio-2.jpg" alt="" />
                                        <div className="portfolio__area-item-image-icon">	<a href="portfolio-details.html"><i className="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                    <div className="portfolio__area-item-content">
                                        <h3><a href="portfolio-details.html">Road Cleaning</a></h3>
                                        <p>Cleaning Services</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 md-mb-30">
                                <div className="portfolio__area-item wow fadeInUp" data-wow-delay="0.9s">
                                    <div className="portfolio__area-item-image">
                                        <img src="/assets/img/portfolio-3.jpg" alt="" />
                                        <div className="portfolio__area-item-image-icon">	<a href="portfolio-details.html"><i className="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                    <div className="portfolio__area-item-content">
                                        <h3><a href="portfolio-details.html">Office Cleaning</a></h3>
                                        <p>Cleaning Services</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 lg-mb-30">
                                <div className="portfolio__area-item wow fadeInUp" data-wow-delay="1.1s">
                                    <div className="portfolio__area-item-image">
                                        <img src="/assets/img/portfolio-5.jpg" alt="" />
                                        <div className="portfolio__area-item-image-icon">	<a href="portfolio-details.html"><i className="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                    <div className="portfolio__area-item-content">
                                        <h3><a href="portfolio-details.html">Gutter Cleaning</a></h3>
                                        <p>Cleaning Services</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 md-mb-30">
                                <div className="portfolio__area-item wow fadeInUp" data-wow-delay="1.4s">
                                    <div className="portfolio__area-item-image">
                                        <img src="/assets/img/portfolio-6.jpg" alt="" />
                                        <div className="portfolio__area-item-image-icon">	<a href="portfolio-details.html"><i className="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                    <div className="portfolio__area-item-content">
                                        <h3><a href="portfolio-details.html">Carpet Cleaning</a></h3>
                                        <p>Cleaning Services</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="portfolio__area-item wow fadeInUp" data-wow-delay="1.7s">
                                    <div className="portfolio__area-item-image">
                                        <img src="/assets/img/portfolio-7.jpg" alt="" />
                                        <div className="portfolio__area-item-image-icon">	<a href="portfolio-details.html"><i className="fas fa-plus"></i></a>
                                        </div>
                                    </div>
                                    <div className="portfolio__area-item-content">
                                        <h3><a href="portfolio-details.html">Freeze Cleaning</a></h3>
                                        <p>Cleaning Services</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="portfolio__area-bottom t-center">
                                    <p>Want to learn more about Our Latest Work?<a href="portfolio-details.html">Click here</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}