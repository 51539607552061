import React, { Component } from "react";
// import SubscribeArea from "../../components/home/SubscribeArea";
import Layout from "../../components/Layout";
import PageHeader from "../../components/service/PageHeader";
import ServiceContent from "../../components/service/ServiceContent";
import ServiceFAQArea from "../../components/service/ServiceFAQArea";
import ServiceTeamArea from "../../components/service/ServiceTeamArea";

export default class Service extends Component {
    componentDidMount(){
        console.log("componentDidMount called");
        window.scrollTo(0,0);
    }
    render() {
        return (
            <>
                <Layout>
                    <PageHeader bannerTitle="Service" />
                    <ServiceContent />
                    <ServiceFAQArea />
                    <ServiceTeamArea />
                    {/* <SubscribeArea /> */}
                </Layout>
            </>
        );
    }
}