import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import PageHeader from '../components/service/PageHeader';
import PaymentDetails from '../components/event/PaymentDetails';

export default class PaymentSuccess extends Component {
    componentDidMount() {
        console.log("Payment Details componentDidMount called ");
        let currentLocation = window.location.toString();
        console.log({ currentLocation });
    }
    render() {
        return (
            <>
            <Helmet>
                <title>Payment Details | Madurai Pandian</title>
                {/* <meta name='description' content='EventDetail - Madurai Pandian' /> */}
            </Helmet>
                <Layout>
                    <PageHeader bannerTitle="Payment Details" />
                    <PaymentDetails data={window.location}/>
                </Layout>
            </>
        );
    }
}
