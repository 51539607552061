import axios from "axios";
import Lightbox from "lightbox-react";
import React, { Component } from "react"

var Api_url = process.env.REACT_APP_API_URL;
console.log({ Api_url });

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

export default class GallerySection extends Component {
    constructor() {
        super();
        this.state = {
            Records: [],
            LoadingFlag: false,
            TotalRecords: 0,
            FilePath: '',
            isOpen: false,
        }
    };

    async getAllRecords() {
        console.log("getAllRecords called ");
        var url = Api_url+`/api/app-settings-content`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 1,
            AppName: "website",
            SettingsName: "gallery",
        };
        this.setState({ LoadingFlag: true });
        console.log({ postData });
        await axios
            .post(url, postData, axiosConfig)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));

                var records = res.data.records;
                console.log({ records });
                var result = records.SettingsValues;
                var n1 = result.length;
                console.log("n1=" + n1);

                this.setState({ LoadingFlag: false, Records: result, TotalRecords: n1 });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        this.getAllRecords();
    }
    render() {
        const { FilePath, isOpen } = this.state;
        return (
            <>

                <div className="team__area section-padding">
                    <div className="container">
                        <div className="row">
                            {this.state.LoadingFlag ? '' :
                                (this.state.TotalRecords > 0 ?
                                    this.state.Records.map((item, index) => (
                                        <div className="col-xl-4 col-lg-4 col-md-6 mb-30" key={index}>
                                            {isOpen && (
                                                <Lightbox
                                                    mainSrc={FilePath}
                                                    onCloseRequest={() => this.setState({ isOpen: false })}
                                                />
                                            )}
                                            {console.log("isOpen = ", isOpen)}
                                            <img src={item.FilePath} alt="" onClick={() => this.setState({ isOpen: true, FilePath: item.FilePath })} style={{ width: 530, height: 250, borderRadius: 10, objectFit: 'cover' }} />
                                            <p className="text-center mt-20">{item.SettingsValuesTitle}</p>

                                        </div>
                                    ))
                                    :
                                    ''
                                )
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

