import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PageHeader from "../../components/service/PageHeader";
import TeamProfileContent from "../../components/team/TeamProfileContent";

export default class TeamProfile extends Component {
    componentDidMount() {
        console.log("TeamProfile componentDidMount called ");
        let currentLocation = window.location.toString();
        console.log({ currentLocation });
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>TeamProfile | Madurai Pandian</title>
                    {/* <meta name='description' content='TeamProfile - Madurai Pandian' /> */}
                </Helmet>
                <Layout>
                    <PageHeader bannerTitle="Team Profile" />
                    <TeamProfileContent data={window.location} />
                </Layout>
            </>
        );
    }
}