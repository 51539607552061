import React, { Component } from "react";
import BulletinList from "../components/bulletin/BulletinList";
// import SubscribeArea from "../components/home/SubscribeArea";
import Layout from "../components/Layout";
import PageHeader from "../components/service/PageHeader";

export default class Bulletin extends Component{
    componentDidMount(){
        console.log("componentDidMount called");
        window.scrollTo(0,0);
    }
    render(){
        return(
            <>
            <Layout>
                <PageHeader bannerTitle="Bulletin" />
                <BulletinList/>
                {/* <SubscribeArea/> */}
            </Layout>
            </>
        );
    }
}