import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ContactArea from "../components/contact/ContactArea";
// import ContactFormArea from "../components/contact/ContactFormArea";
// import SubscribeArea from "../components/home/SubscribeArea";
import Layout from "../components/Layout";
import PageHeader from "../components/service/PageHeader";
export default class Contact extends Component{

    render(){
        return(
            <>
            <Helmet>
                <title>ContactUs | Madurai Pandian</title>
                {/* <meta name='description' content='ContactUs - Madurai Pandian' /> */}
            </Helmet>
            <Layout>
                <PageHeader bannerTitle="Contact" />
                <ContactArea />
                {/* <ContactFormArea/> */}
                {/* <SubscribeArea/> */}
            </Layout>
            </>
        );
    }
}