import React, { Component } from "react";

export default class ServiceTeamArea extends Component{
    render(){
        return(
            <>
            {/* <!-- Team Area Start --> */}
            <div className="team__area section-padding">
                <div className="auto-container">
                    <div className="row mb-60">
                        <div className="col-xl-12">
                            <div className="team__area-title">	<span className="subtitle-one">Our Team</span>
                                <span className="subtitle-two">06</span>
                                <h2>alented Member Ready To Start Your Project</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6 xl-mb-30">
                            <div className="team__area-item wow fadeInUp" data-wow-delay="0.4s">
                                <div className="team__area-item-image">
                                    <img src="assets/img/team-1.jpg" alt="" />
                                    <div className="team__area-item-image-content">
                                        <p>Kitchen Cleaner</p>
                                        <h3><a href="team-details.html">Joe Jackson</a></h3>
                                        <div className="team__area-item-image-content-social">
                                            <div className="team__area-item-image-content-social-icon">
                                                <ul>
                                                    <li><a href="#0"><i className="fab fa-facebook-f"></i></a>
                                                    </li>
                                                    <li><a href="#0"><i className="fab fa-twitter"></i></a>
                                                    </li>
                                                    <li><a href="#0"><i className="fab fa-linkedin-in"></i></a>
                                                    </li>
                                                </ul>
                                            </div>	<a href="#0"><i className="flaticon-share-1"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 md-mb-30">
                            <div className="team__area-item wow fadeInUp" data-wow-delay="0.8s">
                                <div className="team__area-item-image">
                                    <img src="assets/img/team-2.jpg" alt="" />
                                    <div className="team__area-item-image-content">
                                        <p>House Cleaner</p>
                                        <h3><a href="team-details.html">Bill Clinton</a></h3>
                                        <div className="team__area-item-image-content-social">
                                            <div className="team__area-item-image-content-social-icon">
                                                <ul>
                                                    <li><a href="#0"><i className="fab fa-facebook-f"></i></a>
                                                    </li>
                                                    <li><a href="#0"><i className="fab fa-twitter"></i></a>
                                                    </li>
                                                    <li><a href="#0"><i className="fab fa-linkedin-in"></i></a>
                                                    </li>
                                                </ul>
                                            </div>	<a href="#0"><i className="flaticon-share-1"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 md-mb-30">
                            <div className="team__area-item wow fadeInUp" data-wow-delay="1.2s">
                                <div className="team__area-item-image">
                                    <img src="assets/img/team-3.jpg" alt="" />
                                    <div className="team__area-item-image-content">
                                        <p>Office Cleaner</p>
                                        <h3><a href="team-details.html">Dani Warne</a></h3>
                                        <div className="team__area-item-image-content-social">
                                            <div className="team__area-item-image-content-social-icon">
                                                <ul>
                                                    <li><a href="#0"><i className="fab fa-facebook-f"></i></a>
                                                    </li>
                                                    <li><a href="#0"><i className="fab fa-twitter"></i></a>
                                                    </li>
                                                    <li><a href="#0"><i className="fab fa-linkedin-in"></i></a>
                                                    </li>
                                                </ul>
                                            </div>	<a href="#0"><i className="flaticon-share-1"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="team__area-item wow fadeInUp" data-wow-delay="1.6s">
                                <div className="team__area-item-image">
                                    <img src="assets/img/team-4.jpg" alt="" />
                                    <div className="team__area-item-image-content">
                                        <p>Door Cleaner</p>
                                        <h3><a href="team-details.html">Aaron Finch</a></h3>
                                        <div className="team__area-item-image-content-social">
                                            <div className="team__area-item-image-content-social-icon">
                                                <ul>
                                                    <li><a href="#0"><i className="fab fa-facebook-f"></i></a>
                                                    </li>
                                                    <li><a href="#0"><i className="fab fa-twitter"></i></a>
                                                    </li>
                                                    <li><a href="#0"><i className="fab fa-linkedin-in"></i></a>
                                                    </li>
                                                </ul>
                                            </div>	<a href="#0"><i className="flaticon-share-1"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Team Area End --> */}
            </>
        );
    }
}